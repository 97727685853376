import {createContext, useReducer} from "react";
import HistoryReducer from "../reducers/HistoryReducer";
import HistoryService from "../services/HistoryService";
import {NotificationManager} from "react-light-notifications";


const initialState = {
    data: [],
    page: 1,
    pageSize: 0,
    total: 0,
}

export const HistoryContext = createContext(initialState);

export const HistoryProvider = ({children}) => {
    const [state, dispatch] = useReducer(HistoryReducer, initialState);

    function getHistory(prefix, username, date, page) {
        dispatch({
            type: 'NO_DATA',
        })
        HistoryService.getHistory(prefix, username, date, page).then(resp => {
            // console.log(resp.data)
            dispatch({
                type: 'FETCH',
                payload: resp.data
            })

            if (resp.data.total < 1) {
                NotificationManager.warning({
                    title: 'แจ้งเตือน',
                    message: `ไม่พบข้อมูล วันที่ ${date}`,
                });
            }

        }).catch(e => {
            dispatch({
                type: 'NO_DATA',
            })
        })
    }

    function getTopup(prefix, username, type, page, to, from) {
        dispatch({
            type: 'NO_DATA',
        })
        HistoryService.getTopup(prefix, username, to, from, page, type).then(resp => {
            console.log(resp.data)
            dispatch({
                type: 'FETCH_TOPUP',
                payload: resp.data
            })

            if (resp.data.total < 1) {
                NotificationManager.warning({
                    title: 'แจ้งเตือน',
                    message: "ไม่พบข้อมูล",
                });
            }
        }).catch(e => {
            dispatch({
                type: 'NO_DATA',
            })
        })
    }


    return (
        <HistoryContext.Provider value={{
            historyState: state,
            historyData: state.data,
            getHistory,
            getTopup
        }}
        >
            {children}
        </HistoryContext.Provider>
    )

}
