import {useEffect, useState} from "react";
import {Redirect} from "react-router-dom";
import LocalStorageService from "../../services/LocalStorageService";

const localStorageService = LocalStorageService.getService();
const Logout = () => {
    const [prefix, setPrefix] = useState(localStorageService.getPrefix())

    useEffect(() => {
        localStorageService.clearToken()
        setPrefix(localStorageService.getPrefix())

        if (localStorageService.getUniversalLogin() == 1) {
            window.location.href = `/ulogin`;
        } else {
            window.location.href = `/login?prefix=${prefix}`;
        }
    }, [])

    // if (prefix) {
    //     return <Redirect to={}/>
    // }


    return <></>


}

export default Logout
