import React from "react";
import {Route, Switch} from "react-router-dom";

// import Login from "../pages/authen/Login";
// import Register from "../pages/authen/Register";
import SubMainLayout from "./SubMainLayout";
import Logout from "../pages/authen/Logout";
import Login from "../components/Login/Login";
import Register from "../components/Login/Register";
import Verify from "../components/Login/Verify";
import ULogin from "../components/Login/ULogin";


const getRoutes = (
    <Switch>
        <Route exact path="/" component={Login}/>
        <Route exact path="/login" component={Login}/>
        <Route exact path="/ulogin" component={ULogin}/>
        <Route exact path="/logout" component={Logout}/>
        <Route exact path="/register" component={Register}/>
        <Route exact path="/register/otp" component={Verify}/>
        {/*<Route exact path="/register/form" component={RegisterForm}/>*/}


        <Route path="/" component={SubMainLayout}/>

    </Switch>
);

const MainLayout = () => {
    // const [opened] = useState(true);
    return (
        <>
            {getRoutes}
        </>
    );
}

export default MainLayout;
