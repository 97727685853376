import React, {createContext, useReducer} from "react";
import SubmitReducer from "../reducers/SubmitReducer";

const initialState = {
    submitted: false,
    actionStatus: null
}

export const SubmitContext = createContext(initialState);

export const SubmitProvider = ({children}) => {
    const [state, dispatch] = useReducer(SubmitReducer, initialState);

    function setSubmit(status) {
        dispatch({
            type: 'SUBMIT',
            payload: status
        })
    }

    return (
        <SubmitContext.Provider value={{
            submitState: state,
            setSubmit
        }}>
            {children}
        </SubmitContext.Provider>
    );

}
