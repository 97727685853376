import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './assets/vendors/bootstrap/css/bootstrap.min.css';
import './style.css';
// import './style.gold.css';
import "react-light-notifications/lib/main.css";
import {NotificationContainer} from "react-light-notifications";


ReactDOM.render(
    <BrowserRouter>
        <App/>
        <NotificationContainer/>
    </BrowserRouter>

    ,

    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
