import React, {useContext, useEffect, useState} from "react";
import {Breadcrumb} from "react-bootstrap";
import {AccountContext} from "../../context/AccountContext";
import {Link} from "react-router-dom";
import {FaArrowLeft} from "react-icons/all";
import Credit from "../Topup/Credit";


const Password = () => {

    const [data, setData] = useState({
        password: "",
        newPassword: "",
        confirmPassword: "",
    })
    const [errors, setErrors] = useState({})
    const {profile, actionStatus, resetActionStatus, setSubmit, submitted, changePassword} = useContext(AccountContext)

    useEffect(() => {
        resetActionStatus()
        setSubmit(false)
    }, [])

    const handleInput = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleValidation = () => {
        let errors = {}
        let formIsValid = true

        if (!data.password) {
            formIsValid = false
            errors['password'] = "กรุณากรอก รหัสผ่าน";
        }
        if (!data.newPassword) {
            formIsValid = false
            errors['newPassword'] = "กรุณากรอก รหัสผ่านใหม่";
        }
        if (!data.confirmPassword) {
            formIsValid = false
            errors['confirmPassword'] = "กรุณากรอก ยืนยันรกัสผ่านใหม่";
        }

        if (data.newPassword.length < 6) {
            formIsValid = false
            errors['newPassword'] = "รหัสผ่านขั้นต่ำ 6 ตัวอักษร";
        }

        if (data.newPassword != data.confirmPassword) {
            formIsValid = false
            errors['confirmPassword'] = "รหัสผ่านไม่ตรงกัน";
        }
        if (data.newPassword == data.password) {
            formIsValid = false
            errors['newPassword'] = "กรุณาตั้งรหัสผ่านใหม่ที่ไม่เหมือนเดิม";
        }

        setErrors(errors)
        return formIsValid

    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (handleValidation()) {
            setSubmit(true)
            const params = {
                password: data.password,
                newPassword: data.newPassword,
                prefix: profile.prefix
            }
            changePassword(params)
        }

    }

    return (
        <>
            {/*<Breadcrumb>*/}
            {/*    <Breadcrumb.Item href="#">หน้าแรก</Breadcrumb.Item>*/}
            {/*    <Breadcrumb.Item href="/account">ข้อมูลส่วนตัว</Breadcrumb.Item>*/}
            {/*    <Breadcrumb.Item active>เปลี่ยนรหัสผ่าน</Breadcrumb.Item>*/}
            {/*</Breadcrumb>*/}

            <div className="heading-title">
                <div>
                    <h3>เปลี่ยนรหัสผ่าน</h3>
                    <div>ตั้งรหัสผ่านใหม่</div>
                </div>
                <Link to="/dashboard" className="back">
                    <FaArrowLeft/> ย้อนกลับ
                </Link>
            </div>

            <Credit profile={profile} />

            <div className="row">
                <div className="col-lg-12">
                    <div className="box nor position-relative mb-4">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <form onSubmit={e => handleSubmit(e)}>
                                    <div className="p-4">
                                        <div className="heading line-o h4 mb-4">
                                            <i>เปลี่ยนรหัสผ่าน</i>
                                        </div>

                                        {/*{actionStatus == "success" && (*/}
                                        {/*    <div className="alert alert-success">แก้ไขข้อมูลสำเร็จ</div>)}*/}
                                        {/*{actionStatus == "error" && (*/}
                                        {/*    <div className="alert alert-danger">ไม่สามารถแก้ไขข้อมูลได้ กรุณาลองใหม่อีกครั้ง</div>)}*/}

                                        <div className="form-group">
                                            <input type="password" autoComplete="new-password" name="password" onChange={e => handleInput(e)} placeholder="รหัสผ่านเก่า" className="form-control "/>
                                            <div className="text-danger"><small>{errors["password"]}</small></div>
                                        </div>
                                        <div className="form-group">
                                            <input type="password" autoComplete="new-password" name="newPassword" onChange={e => handleInput(e)} placeholder="รหัสผ่านใหม่" className="form-control "/>
                                            <div className="text-danger"><small>{errors["newPassword"]}</small></div>
                                        </div>
                                        <div className="form-group">
                                            <input type="password" autoComplete="new-password" name="confirmPassword" onChange={e => handleInput(e)} placeholder="ยืนยันรกัสผ่านใหม่" className="form-control "/>
                                            <div className="text-danger"><small>{errors["confirmPassword"]}</small>
                                            </div>
                                        </div>

                                        {submitted ? (
                                            <button disabled={true} className="btn btn-warning btn-lg btn-block">รอสักครู่</button>) : (
                                            <button onClick={e => handleSubmit(e)} className="btn btn-warning btn-lg btn-block">เปลี่ยนรหัสผ่าน</button>)}
                                        {/*<button type="button" onClick={e => handleSubmit(e)} className="btn btn-primary btn-lg btn-block">เปลี่ยนรหัสผ่าน</button>*/}

                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>


                </div>
            </div>

        </>
    );
}


export default Password
