import {Link, Redirect, withRouter} from "react-router-dom";
import {App} from "../../config/App";
import React, {useContext, useEffect, useState} from "react";
import {LoginContext} from "../../context/LoginContext";
import {NotificationManager} from "react-light-notifications";
import LocalStorageService from "../../services/LocalStorageService";
import CurrencyFormat from "react-currency-format";
import {FaLine} from "react-icons/fa";


const localStorageService = LocalStorageService.getService();

const Register = () => {

    const {loginState, otp, clearRedirect, setSubmit, otpRef} = useContext(LoginContext)
    const [phone, setPhone] = useState("")
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [prefix, setPrefix] = useState("")
    const [ref, setRef] = useState("")
    const [promotion, setPromotion] = useState(false)

    useEffect(() => {
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        const mPrefix = params.get("prefix")
        const mRef = params.get("ref")

        if (typeof mPrefix == "undefined" || mPrefix == "" || mPrefix == null || mPrefix == "null") {
            if (App.prefix != ``) {
                let url = `/login?prefix=${App.prefix}`
                if (mRef != "" && mRef != null) {
                    url += `&ref=${mRef}`
                }
                window.location.href = url
            } else {
                NotificationManager.error({
                    title: 'ผิดพลาด',
                    message: "การเข้าใช้งาน Prefix ไม่ถูกต้อง",
                });
            }
        }

        setPrefix(mPrefix)

        if (typeof mRef != 'undefined' && mRef != "" && mRef != null) {
            localStorageService.setRef(mRef)
            setRef(mRef)
            otpRef(mPrefix, mRef)
        } else {
            localStorageService.setRef("")
        }
        clearRedirect()
    }, [])


    useEffect(() => {
        document.body.classList.add(`login`)
        setSubmit(false)

        if (localStorageService.getAccessToken() != `` && localStorageService.getAccessToken() != null) {
            window.location.href = `/dashboard`
        }
    }, [])


    useEffect(() => {
        if (loginState.responseFail) {
            NotificationManager.error({
                title: 'ผิดพลาด',
                message: loginState.responseFail.message,
            });
        }
    }, [loginState.responseFail])

    const handleUsername = (v) => {
        // setUsername(v)
        if (v.length <= 10 && !isNaN(v)) {
            setPhone(v)
            setUsername(v)
        }
    }

    const handlePromotion = (e) => {
        const {checked} = e
        // alert(checked)
        setPromotion(checked)
    }

    const handleOtpRequest = (e) => {
        e.preventDefault()
        setSubmit(true)
        // console.log(phone.length)

        if (isNaN(phone) || phone == "" || phone.length != 10) {
            NotificationManager.error({
                title: 'ผิดพลาด',
                message: 'หมายเลขโทรศัพท์ไม่ถูกต้อง',
            });
            // addToast("หมายเลขโทรศัพท์ไม่ถูกต้อง", {appearance: 'error', autoDismiss: true});
            setSubmit(false)
            return
        }
        const pattern = /^[A-Za-z0-9_.]+$/;
        if (!pattern.test(username)) {
            NotificationManager.error({
                title: 'ผิดพลาด',
                message: 'ระบุ Username ไม่ถูกต้อง',
            });
            setSubmit(false)
            return
        }

        if (password.length < 6) {
            NotificationManager.error({
                title: 'ผิดพลาด',
                message: 'กรุณาระบุ Password ตั้งแต่ 6 ตัวอักษรขึ้นไป',
            });
            setSubmit(false)
            return
        }
        // alert(promotion)
        otp(phone, prefix, username, password, promotion)
    }

    if (loginState.redirect) {
        return <Redirect to={loginState.redirect}/>;
    }


    return (
        <>
            <div className="row justify-content-center">
                <div className="col-xl-3 col-lg-6 col-md-8">
                    <div className="container-fluid my-5">
                        <div className="mb-4 text-center">
                            <div className="mb-2">
                                <img src={App.logo} alt="{App.app_name}" className="w-75"/>
                            </div>

                        </div>
                        <div className="h6 mb-1">กรอกเบอร์โทรศัพท์</div>
                        <small className="text-muted mb-3 d-block">ลงทะเบียนทางโทรศัพท์เพื่อรับ OTP ยืนยันตน</small>
                        <div className="mb-4">
                            <div className="form-group">
                                <input type="text" autoComplete="new-password" placeholder="ระบุหมายเลขโทรศัพท์" max={10} value={username} onChange={e => handleUsername(e.target.value)} className="form-control"/>
                            </div>
                            <div className="form-group">
                                <input type="password" autoComplete="new-password" placeholder="รหัสผ่าน" onChange={e => setPassword(e.target.value)} className="form-control"/>
                            </div>
                            {/*<div className="form-group">*/}
                            {/*    <label className="text-left d-block">รับโปรโมชั่น</label>*/}
                            {/*    <select className="form-control" onChange={e => setPromotion(e.target.value)}>*/}
                            {/*        <option value={false} selected={promotion == false ? true : false}>ไม่รับ</option>*/}
                            {/*        <option value={true} selected={promotion}>รับ</option>*/}
                            {/*    </select>*/}
                            {/*</div>*/}
                            <div className="form-check mb-3">
                                <input type="checkbox" checked={promotion} onClick={e => handlePromotion(e.target)} className="form-check-input" id="promotion"/>
                                <label className="form-check-label" htmlFor="promotion">รับโปรโมชั่น</label>
                            </div>
                            {loginState.refPro && (
                                <>
                                    <div className="mt-3 mb-4">
                                        <div className="ref-title mb-1"> โปรโมชั่น : {loginState.refPro.name}</div>
                                        {loginState.refPro?.base64Img && (
                                            <div className="my-3">
                                                <img src={loginState.refPro?.base64Img} alt="image" className="w-100"/>
                                            </div>
                                        )}
                                        <div className="p-2">
                                            <p className="mb-1">รับโบนัสสูงสุด <CurrencyFormat value={loginState.refPro.maxBonus} displayType={'text'} suffix=" บาท" thousandSeparator={true}/>
                                            </p>
                                            <div>เงื่อนไข</div>
                                            <ul>
                                                {loginState.refPro.minDeposit &&
                                                    <li>ฝากขั้นต่ำ <CurrencyFormat value={loginState.refPro.minDeposit} displayType={'text'} suffix=" บาท" thousandSeparator={true}/>
                                                    </li>}
                                                {loginState.refPro.turnOver &&
                                                    <li>Turn Over {loginState.refPro.turnOver} เท่า</li>}
                                                {loginState.refPro.minWithdraw &&
                                                    <li>ถอนขั้นต่ำ <CurrencyFormat value={loginState.refPro.minWithdraw} displayType={'text'} suffix=" บาท" thousandSeparator={true}/>
                                                    </li>}
                                            </ul>
                                            {loginState.refPro?.options && (loginState.refPro.options).length > 0 && (
                                                <>
                                                    <div className="mt-3">เงื่อนไขการรับโบนัส</div>
                                                    <ul>
                                                        {loginState.refPro?.options.map(v => (
                                                            <li>
                                                                ฝาก <CurrencyFormat value={v.min} displayType={'text'} thousandSeparator={true}/> - <CurrencyFormat value={v.max} displayType={'text'} thousandSeparator={true}/> รับโบนัส {v.bonus} {loginState.refPro.bonusType == 1 ? '' : '%'}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </>
                                            )}

                                        </div>
                                    </div>
                                </>
                            )}
                            {(prefix && prefix != "null") ? (
                                <>
                                    {loginState.submitted ? (
                                        <button disabled={true} className="btn btn-warning btn-block">รอสักครู่...</button>) : (
                                        <button className="btn btn-warning text-bold btn-block" onClick={handleOtpRequest}>ขอรับรหัส OTP</button>)}
                                    {ref && ref != null ?
                                        <Link to={`/login?prefix=${prefix}&ref=${ref}`} className="btn btn-dark text-bold btn-block">เข้าสู่ระบบ</Link> :
                                        <Link to={`/login?prefix=${prefix}`} className="btn btn-dark text-bold btn-block">เข้าสู่ระบบ</Link>}
                                </>
                            ) : (
                                <button className="btn btn-warning  btn-block" disabled={true}>การเข้าใช้งานไม่ถูกต้อง</button>)}
                        </div>

                        {typeof App.lineQrcode !== `undefined` && App.lineQrcode !== `` && (
                            <div className="my-5 text-center">
                                <img src={App.lineQrcode} className="w-50" alt=""/>
                                <div className="mt-3">
                                    <a href={App.lineUrl} target="_blank" className="btn btn-success px-4 py-1 d-inline-flex align-items-center text-uppercase font-weight-bold">
                                        <FaLine className="mr-2" style={{fontSize: "22px"}}/>
                                        {App.lineId}
                                    </a>
                                </div>
                            </div>
                        )}


                        <div className="text-center">
                            <h1 className="h3 text-uppercase font-weight-bold mb-0 text-warning">{App.app_name}</h1>
                            <div className="text-white font-weight-bold ">LIVE CASINO & SLOT BETTING</div>
                            <small>
                                Copyright © 2021 <span className="text-warning text-uppercase">{App.app_name} </span> All rights reserved.
                            </small>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default withRouter(Register)
