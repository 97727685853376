import http from "../http-common";
import {App} from "../config/App";
import LocalStorageService from "./LocalStorageService";


const enpoint = App.enpoint
// const enpoint = ``


const signin = (username, password, prefix) => {

    const data = {
        'username': username,
        'password': password,
        'prefix': prefix
    }
    return http.post(enpoint + `/user/login`, data)
}

const otp = (phone, prefix, username, promotion, password) => {
    const localStorageService = LocalStorageService.getService();
    const data = {
        'phone': phone,
        'prefix': prefix,
        'username': username,
        'password': password,
        'ref' : '',
        'promotion': promotion,
    }
    const ref = localStorageService.getRef()
    if (ref != null && ref != "") {
        data.ref = ref
    }
    return http.post(`/otp`, data)
}

const getRefPro = (prefix, ref) => {
    // const localStorageService = LocalStorageService.getService();
    const data = {
        'prefix': prefix,
        'ref' : ref,
    }
    return http.post(`/otp/ref`, data)
}

const otpVerify = (phone, code, otp, prefix, username, password, promotion) => {
    const localStorageService = LocalStorageService.getService();
    let data = {
        'phone': phone,
        'code': code,
        'otp': otp,
        'prefix': prefix,
        'username': username,
        'password': password,
        'ref' : '',
        'promotion': (promotion == "true" || promotion == true) ? true : false,
    }
    const ref = localStorageService.getRef()
    if (ref != null && ref != "") {
        data.ref = ref
    }
    return http.post(`/otp/verify`, data)
}


const getProfile = () => {
    return http.get(enpoint + `/user/me`)
}
const updateProfile = (data) => {
    return http.patch(enpoint + `/user/me`, data)
}
const changePassword = (data) => {
    return http.patch(enpoint + `/user/password`, data)
}

const getAgentBank = () => {
    return http.get(enpoint + `/user/banking`)
}

const withdraw = (data) => {
    return http.post(enpoint + `/user/withdraw`, data)
}

const getChilds = (prefix, page) => {
    return http.get(enpoint + `/user/affiliate/lists?page=${page}`)
}

const togglePromotion = () => {
    return http.post(enpoint + `/user/promotion/toggle`, null)
}

export default {
    signin,
    otp,
    otpVerify,
    getProfile,
    updateProfile,
    changePassword,
    getAgentBank,
    withdraw,
    getRefPro,
    getChilds,
    togglePromotion
}
