import React, {useContext, useEffect, useState} from "react";
import {AccountContext} from "../../context/AccountContext";
import {Link} from "react-router-dom";
import {Bank} from "../../config/Bank";
import {SubmitContext} from "../../context/SubmitContext";
import {Breadcrumb} from "react-bootstrap";
import {FaArrowLeft, FaEdit} from "react-icons/all";
import Credit from "../Topup/Credit";
import TogglePromotion from "./TogglePromotion";


const Profile = () => {

    const {profile} = useContext(AccountContext)
    const [bankName, setBankName] = useState("")

    useEffect(() => {
        if (profile) {
            const bank = Bank

            const b = bank.find(x => x.code === profile.bankName);
            if (b) {
                setBankName(b.name)
            }
        }
    }, [profile])


    return (
        <>

            <div className="heading-title">
                <div>
                    <h3>บัญชีของฉัน</h3>
                    <div>ข้อมูลส่วนตัว</div>
                </div>
                <Link to="/dashboard" className="back">
                    <FaArrowLeft/> ย้อนกลับ
                </Link>
            </div>

            <Credit profile={profile}/>
            <TogglePromotion/>

            <div className="row">
                <div className="col-lg-12">
                    <div className="box nor position-relative mb-4">
                        <div className="p-4">
                            <div className="heading line-o h4 mb-4">
                                <i>ข้อมูลส่วนตัว</i>
                            </div>

                            {/*<div className="form-group mb-0 row">*/}
                            {/*    <label className="col-sm-2 col-4 col-form-label"><i>หมายเลขโทรศัพท์</i></label>*/}
                            {/*    <div className="col-sm-10 col-8">*/}
                            {/*        <div className="py-2">: {profile.phone}</div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*<div className="form-group mb-0 row">*/}
                            {/*    <label className="col-sm-2 col-4 col-form-label"><i>ชื่อ</i></label>*/}
                            {/*    <div className="col-sm-10 col-8">*/}
                            {/*        <div className="py-2">: {profile.name}</div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="form-group mb-0 row">
                                <label className="col-sm-2 col-4 col-form-label"><i>ยูสเซอร์เนม</i></label>
                                <div className="col-sm-10 col-8">
                                    <div className="py-2">: {profile && (
                                        <>
                                            {profile.prefix}{profile.username}
                                        </>
                                    )}

                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb-0 row">
                                <label className="col-sm-2 col-4 col-form-label"><i>ชื่อบัญชี</i></label>
                                <div className="col-sm-10 col-8">
                                    <div className="py-2">: {profile.bankAccountName ? profile.bankAccountName : (
                                        <i className="font-weight-light text-muted"><small>ยังไม่ระบุ</small></i>)}</div>
                                </div>
                            </div>
                            <div className="form-group mb-0 row">
                                <label className="col-sm-2 col-4 col-form-label"><i>เลขที่บัญชี</i></label>
                                <div className="col-sm-10 col-8">
                                    <div className="py-2">: {profile.bankNumber ? profile.bankNumber : (
                                        <i className="font-weight-light text-muted"><small>ยังไม่ระบุ</small></i>)}</div>
                                </div>
                            </div>
                            <div className="form-group mb-4 row">
                                <label className="col-sm-2 col-4 col-form-label"><i>ธนาคาร</i></label>
                                <div className="col-sm-10 col-8">
                                    <div className="py-2">: {profile.bankName ? bankName : (
                                        <i className="font-weight-light text-muted"><small>ยังไม่ระบุ</small></i>)}</div>
                                </div>
                            </div>
                            <div className="form-group mb-0 row">
                                <div className="col-sm-01 col-12">
                                    <Link to="/account/edit" class="btn btn-warning btn-block px-5">
                                        <FaEdit/> <i>แก้ไขข้อมูล</i>
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div>

        </>
    );
}


export default Profile
