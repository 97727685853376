import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {AiFillDollarCircle, FaArrowLeft, FaHistory} from "react-icons/all";
import kbank from "../../assets/images/banks/kbank.png"
import banner_golden1 from "../../assets/images/banners/banner_golden1.png"
import banner_golden2 from "../../assets/images/banners/banner_golden2.png"
import banner_joker from "../../assets/images/banners/banner_joker.png"
import banner_live from "../../assets/images/banners/banner_live.png"
import {Breadcrumb} from "react-bootstrap";
import {AccountContext} from "../../context/AccountContext";
import CurrencyFormat from "react-currency-format";
import ProviderGaming from "../Games/ProviderGaming";
import {Bank} from "../../config/Bank";
import {NotificationManager} from "react-light-notifications";
import Popup from "./Popup";
import Credit from "./Credit";


const Withdraw = () => {

    // const {profile} = useContext(AccountContext)
    const {getAgentBank, agentBank, profile, addWithdraw} = useContext(AccountContext)
    const [amount, setAmount] = useState(0)

    useEffect(() => {
        getAgentBank();
    }, [])

    useEffect(() => {
        if (profile) {
            setAmount(profile && profile.balance ? profile.balance : 0)
        }
    }, [profile])

    const getBankIcon = (code) => {
        code = code.toLowerCase()
        return `/assets/images/banks/${code}.png`
    }

    const getBankName = (code) => {
        const bank = Bank

        const b = bank.find(x => x.code === profile.bankName);
        if (b) {
            return b.name
        }
    }


    const handleAmount = (v) => {
        if (!isNaN(v)) {
            setAmount(v)
        } else {
            setAmount("")
        }
    }

    const handleSubmit = () => {

        if (isNaN(amount) || amount <= 0 || amount > profile.balance) {
            // alert("จำนวนเงินไม่ถูกต้อง")
            NotificationManager.error({
                title: 'ผิดพลาด',
                message: "จำนวนเงินไม่ถูกต้อง",
            });
            return;
        }

        if (window.confirm("ยืนยัน")) {
            const data = {
                amount: amount * 1
            }
            addWithdraw(data)
        }
    }

    const addDefaultSrc = (ev) => {
        ev.target.src = '/assets/images/banks/default.png'
    }

    return (
        <>
            <Popup profile={profile}/>
            <div className="heading-title">
                <div>
                    <h3>ถอนเงิน</h3>
                    <div>ระบบแจ้งถอนเงินอัตโนมัติ</div>
                </div>
                <Link to="/dashboard" className="back">
                    <FaArrowLeft/> ย้อนกลับ
                </Link>
            </div>
           <Credit profile={profile} />


            <div className="row">
                <div className="col-lg-12">

                    <div className="text-center mb-3">
                        <h4><i>บัญชีรับเงิน</i></h4>
                        <div className="logo">
                            {profile && profile.bankName && (
                                <img src={getBankIcon(profile.bankName)} onError={e => addDefaultSrc(e)} alt={profile.bankName}/>)}
                        </div>
                    </div>
                    <div className=" text-success text-center mb-3">
                        <div><small className="text-muted">ธนาคาร</small></div>
                        <i className="h4 mb-0">
                            {getBankName(profile && profile.bankName ? profile.bankName : "")}
                        </i>
                    </div>

                    <div className="text-center mb-3">
                        <div><small className="text-muted">ชื่อบัญชี</small></div>
                        <i className="h4">
                            {profile && profile.bankAccountName ? profile.bankAccountName : "กำลังโหลดข้อมูล..."}
                        </i>
                    </div>

                    <div className="text-center mb-3">
                        <div><small className="text-muted">เลขที่บัญชี</small></div>
                        <i className="h4">
                            {profile && profile.bankNumber ? profile.bankNumber : "กำลังโหลดข้อมูล..."}
                        </i>
                    </div>

                    {profile?.usingPromo?.type > 0 && (
                        <div className="alert alert-warning">
                            <div className="h6 mb-0">โปรโมชั่นของคุณ</div>
                            {profile?.usingPromo?.name}
                            {profile?.usingPromo?.turnOver > 0 && (
                                <>และทำ TurnOver {profile?.usingPromo?.turnOver} เท่าจากยอดเครดิต ฉ เวลาที่ฝาก</>
                            )}
                        </div>
                    )}


                    <div className="row justify-content-center mb-4">
                        <div className="col-lg-4">
                            <div className=" mb-3">
                                <div className="input-group input-group-custom">
                                    <input type="text" className="form-control form-control-lg" value={amount} onChange={e => handleAmount(e.target.value)} placeholder="ระบุจำนวนเงินที่ต้องการถอน"/>
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" type="button">บาท</button>
                                    </div>
                                </div>
                            </div>
                            <button type="button" onClick={handleSubmit} className="btn btn-primary btn-block btn-lg">
                                <i>ถอนเครดิต</i></button>
                        </div>
                    </div>




                    <ProviderGaming/>

                </div>
            </div>

        </>
    );
}


export default Withdraw
