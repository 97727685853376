export const Bank = [
    {name: "ธนาคารกสิกรไทย", code: "KBANK"},
    {name: "ธนาคารกรุงเทพ", code: "BBL"},
    {name: "ธนาคารกรุงไทย", code: "KTB"},
    {name: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร", code: "BAAC"},
    // {name: "ธนาคารทหารไทย", code: "TMB"},
    {name: "ธนาคารทหารไทยธนชาต", code: "TTB"},
    {name: "ธนาคารไอซีบีซี (ไทย)", code: "ICBC"},
    {name: "ธนาคารไทยเครดิตเพื่อรายย่อย", code: "TCD"},
    {name: "ธนาคารซิตี้แบงก์", code: "CITI"},
    {name: "ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)", code: "SCBT"},
    {name: "ธนาคารซีไอเอ็มบีไทย", code: "CIMB"},
    {name: "ธนาคารยูโอบี", code: "UOB"},
    {name: "ธนาคารกรุงศรีอยุธยา", code: "BAY"},
    {name: "ธนาคารออมสิน", code: "GSB"},
    {name: "ธนาคารเอชเอสบีซี ประเทศไทย", code: "HSBC"},
    {name: "ธนาคารมิซูโฮ คอร์ปอเรต", code: "MIZUHO"},
    {name: "ธนาคารอาคารสงเคราะห์", code: "GHB"},
    {name: "ธนาคารแลนด์ แอนด์ เฮ้าส์", code: "LHBANK"},
    // {name: "ธนาคารธนชาต", code: "TBANK"},
    {name: "ธนาคารทิสโก้", code: "TISCO"},
    {name: "ธนาคารเกียรตินาคิน", code: "KKP"},
    {name: "ธนาคารไทยพาณิชย์", code: "SCB"},
    {name: "ธนาคารอิสลามแห่งประเทศไทย", code: "IBANK"},
]

export const SiteName = "Slotautospin"
