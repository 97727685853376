import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {FaArrowLeft, FaClock} from "react-icons/all";
import btn_fb from "../../assets/images/buttons/btn-fb.png"
import btn_line from "../../assets/images/buttons/btn-line.png"
import Credit from "../Topup/Credit";
import {AccountContext} from "../../context/AccountContext";
import {NotificationManager} from "react-light-notifications";
import {App} from "../../config/App";
import {isMobile} from 'react-device-detect';
import CurrencyFormat from "react-currency-format";
import Moment from "react-moment";
import moment from "moment";
import Pagination from "react-js-pagination";


const AffSummary = () => {
    const {profile, getAffChild, childs} = useContext(AccountContext)
    const [url, setUrl] = useState(``)
    const [activePage, setActivePage] = useState(1)

    useEffect(() => {
        if (typeof profile.prefix != `undefined`) {
            // const u = `https://${window.location.host}/register?ref=${profile.ref}`
            const u = `https://${window.location.host}/register?prefix=${profile.prefix}&ref=${profile.ref}`
            setUrl(u)
            getAffChild(profile?.prefix, 1)
        }
    }, [profile])


    const copyUrlText = () => {
        navigator.clipboard.writeText(url);
        NotificationManager.success({
            title: 'สำเร็จ',
            message: `คัดลอกลิ้งรับทรัพย์แล้ว`,
        });
    }

    const handlePageChange = (pageNumber) => {
        // console.log(`active page is ${pageNumber}`);
        setActivePage(pageNumber);
        getAffChild(profile.prefix, pageNumber)
    }

    const shareThis = (mode = `facebook`) => {


        const eUrl = encodeURIComponent(url)
        let title = `สมัครสมาชิก ${App.app_name}`
        let u = `https://www.facebook.com/sharer.php?t=${title}&u=${eUrl}`
        switch (mode) {
            case `line` :
                u = `https://lineit.line.me/share/ui?url=${eUrl}`
                break
        }
        if (isMobile) {
            window.location.href = u
        } else {
            let h, w, wh, ww;
            wh = window.innerHeight;
            ww = window.innerWidth;
            h = Math.min(600, .6 * wh);
            w = Math.min(800, .8 * ww);
            window.open(u, '', ["height=" + h, "left=" + ((ww - w) / 2), "top=" + ((wh - h) / 2), "width=" + w, 'status=1', 'toolbar=0'].join(','));
        }
    }

    return (
        <>
            <div className="heading-title">
                <div>
                    <h3>ลิ้งรับทรัพทย์</h3>
                    <div>แชร์ลิ้งเพื่อแนะนำเพื่อนของคุณ</div>
                </div>
                <Link to="/dashboard" className="back">
                    <FaArrowLeft/> ย้อนกลับ
                </Link>
            </div>

            <Credit profile={profile}/>

            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="input-group input-group-custom mb-4">
                        <input type="text" className="form-control link-mobile-input" value={url}/>
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary" onClick={(e) => copyUrlText()} type="button">คัดลอก</button>
                        </div>
                    </div>

                    <div className="row mb-4">
                        <div className="col-lg-6 text-center mb-4 mb-lg-0">
                            <img src={btn_fb} alt="facebook" className="w-100 pointer" onClick={() => shareThis()}/>
                        </div>
                        <div className="col-lg-6 text-center mb-4 mb-lg-0">
                            <img src={btn_line} alt="line" className="w-100 pointer" onClick={() => shareThis(`line`)}/>
                        </div>
                    </div>


                </div>
            </div>


            <div className="heading-title mt-4">
                <div>
                    <h3>สายงาน</h3>
                    <div>รายชื่อสมาชิกที่อยู่ในสานงานคุณ</div>
                </div>
            </div>

            <div className="table-responsive">
                <table className="table table-custom table-striped">
                    <thead>
                    <tr>
                        <th className="text-left border-top-0">
                            <div className="h6 m-0 text-white"><i>ID</i></div>
                        </th>
                        <th className="text-left border-top-0">
                            <div className="h6 m-0 text-white"><i>ชื่อ</i></div>
                        </th>
                        <th className="text-left border-top-0">
                            <div className="h6 m-0 text-white"><i>หมายเลขโทรศัพท์</i></div>
                        </th>
                        <th className="text-left border-top-0">
                            <div className="h6 m-0 text-white"><i>สมัครวันที่</i></div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {childs && childs?.users ? (
                        <>
                            {childs?.users.map((item, index) => (
                                <tr key={index}>
                                    <td className="text-left">{item.username}</td>
                                    <td className="text-left">{item.name}</td>
                                    <td className="text-left">{item.phone}</td>
                                    <td className="text-left">{moment(item.createdTime).format("YYYY-MM-DD HH:mm")}</td>
                                </tr>
                            ))}
                        </>
                    ) : (
                        <tr>
                            <td colSpan={6} className="text-center">ไม่พบข้อมูล</td>
                        </tr>
                    )}

                    </tbody>
                </table>
            </div>
            {childs && childs?.users && (

                <div className="d-flex justify-content-center">
                    <Pagination
                        itemClass={"page-item"}
                        linkClass={"page-link"}
                        activePage={activePage}
                        itemsCountPerPage={childs.pageSize}
                        totalItemsCount={childs.count ? childs.count : 0}
                        pageRangeDisplayed={5}
                        onChange={e => handlePageChange(e)}
                    />
                </div>
            )}


        </>
    );
}


export default AffSummary
