import LocalStorageService from "../services/LocalStorageService";

const localStorageService = LocalStorageService.getService();


export default function AccountReducer(state = [], action) {
    switch (action.type) {

        case 'GET_PROFILE':
            return {
                ...state,
                profile: action.payload
            }
        case 'GET_AGENT_BANK':
            return {
                ...state,
                agentBank: action.payload
            }
        case 'ACTION_STATUS':
            return {
                ...state,
                actionStatus: action.payload,
                submitted: false,
            }
        case 'SUBMIT':
            return {
                ...state,
                submitted: action.payload
            }
        case 'RELOAD_PROFILE' :
            return {
                ...state,
                reloadProfile: action.payload
            }
        case 'ACTIVE_MENU' :
            return {
                ...state,
                menuActive: action.payload
            }
        case 'GET_CHILDS' :
            // console.log(action.payload)
            return {
                ...state,
                childs: action.payload
            }
        default:
            return state
    }
}
