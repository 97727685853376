import {useEffect, useState} from "react";

const LEFT_PAGE = 'LEFT';
const RIGHT_PAGE = 'RIGHT';
const range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
        range.push(i);
        i += step;
    }

    return range;
}

const Paginate = ({totalRecords, pageLimit, pageNeighbours, getGames, provider}) => {
    const [currentPage, setCurrentPage] = useState(1)
    const [pages, setPages] = useState([])
    const [totalPages, setTotalPages] = useState(0)
    useEffect(() => {
        pageNeighbours = typeof pageNeighbours === 'number' ? Math.max(0, Math.min(pageNeighbours, 2)) : 0
        setTotalPages(Math.ceil(totalRecords / pageLimit))
    }, [])

    useEffect(() => {
        if(totalPages > 0)
            fetchPageNumbers()
    }, [totalPages])

    const fetchPageNumbers = () => {

        const totalNumbers = (pageNeighbours * 2) + 3;
        const totalBlocks = totalNumbers + 2;

        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
            let pages = range(startPage, endPage);

            const hasLeftSpill = startPage > 2;
            const hasRightSpill = (totalPages - endPage) > 1;
            const spillOffset = totalNumbers - (pages.length + 1);

            switch (true) {

                case (hasLeftSpill && !hasRightSpill): {
                    const extraPages = range(startPage - spillOffset, startPage - 1);
                    pages = [LEFT_PAGE, ...extraPages, ...pages];
                    break;
                }

                case (!hasLeftSpill && hasRightSpill): {
                    const extraPages = range(endPage + 1, endPage + spillOffset);
                    pages = [...pages, ...extraPages, RIGHT_PAGE];
                    break;
                }

                case (hasLeftSpill && hasRightSpill):
                default: {
                    pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
                    break;
                }
            }
            setPages([1, ...pages, totalPages])
        }
        setPages(range(1, totalPages))
    }


    const gotoPage = page => {
        setCurrentPage(page)
        getGames(provider, page)
    }

    const handleClick = (evt, page) => {
        evt.preventDefault();
        window.scrollTo(0, 0)
        gotoPage(page);
    }

    const handleMoveLeft = evt => {
        evt.preventDefault();
        gotoPage(currentPage - (pageNeighbours * 2) - 1);
    }

    const handleMoveRight = evt => {
        evt.preventDefault();
        gotoPage(currentPage + (pageNeighbours * 2) + 1);
    }

    return (
        <>
            {totalRecords > 0 && totalPages != 1 && (
                <>
                    <div className="d-flex align-items-center justify-content-center mt-4">
                        <nav aria-label="Page navigation example">
                            <ul className="pagination">
                                {console.log(pages)}
                                {pages.map((p, index) => (
                                    <>
                                        {(p == LEFT_PAGE) && (
                                            <li key={index} className="page-item"><a className="page-link" href="#">Previous</a></li>
                                        )}
                                        {(p == RIGHT_PAGE) && (
                                            <li key={index} className="page-item"><a className="page-link" href="#">Next</a></li>
                                        )}
                                        <li key={index} className={"page-item " + (currentPage == p ? 'active' : '')}><a onClick={e => handleClick(e, p)} className="page-link" href="#">{p}</a></li>
                                    </>
                                ))}

                            </ul>
                        </nav>
                    </div>
                </>
            )}

        </>
    )
}

export default Paginate
