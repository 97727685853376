import {axioClientBot} from "../http-common";
import http from "../http-common";


const getGames = (provider, page) => {
    page = typeof page != 'undefined' ? page : 1
    return axioClientBot.get(`/game?provider=${provider}&page=${page}`)
}


const lunchGame = (productId, gameCode, mobile) => {
    const data = {
        'productId': productId,
        'gameCode': gameCode,
        'isMobile' : mobile
    }
    return http.post('/lunch', data)
}

export default {
    getGames,
    lunchGame
}
