import React, {useContext, useEffect, useState} from "react";
import {Bank} from "../../config/Bank";
import {AccountContext} from "../../context/AccountContext";
import {Breadcrumb} from "react-bootstrap";
import {NotificationManager} from "react-light-notifications";
import {Link} from "react-router-dom";
import {FaArrowLeft} from "react-icons/all";
import Credit from "../Topup/Credit";

const state = {
    name: null,
    phone: null,
    bankNumber: null,
    bankAccountName: null,
    bankAccountNameEN: null,
    bankName: null,
}
const EditProfile = () => {

    const [data, setData] = useState(state)
    const [errors, setErrors] = useState({})
    const [bank, setBank] = useState(Bank)
    const [hasBank, setHasBank] = useState(false)
    const {profile, updateProfile, actionStatus, resetActionStatus, setSubmit, submitted} = useContext(AccountContext)

    useEffect(() => {
        if (profile && typeof profile.id != "undefined") {

            if (profile.bankAccountName != "" && profile.bankAccountNameEN != "" && profile.bankNumber != "") {

                NotificationManager.warning({
                    title: 'แจ้งเตือน',
                    message: "หากต้องการแก้ไขข้อมูล กรุณาติดต่อทีมงาน",
                });

                setHasBank(true)
                // return
            }

            const nameE = profile.name == profile.phone ? "" : profile.name
            setData({
                name: nameE,
                phone: profile.phone,
                bankName: profile.bankName,
                bankAccountName: profile.bankAccountName,
                bankAccountNameEN: profile.bankAccountNameEN,
                bankNumber: profile.bankNumber,
            })
            setErrors({})
        }
    }, [profile])

    useEffect(() => {
        if (actionStatus == "updated") {
            setHasBank(true)
        }
    }, [actionStatus])

    useEffect(() => {
        resetActionStatus()
    }, [])

    useEffect(() => {
        setSubmit(false)
    }, [])


    const handleInput = (e) => {
        // console.log(e.target.name)

        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleValidation = () => {
        let errors = {}
        let formIsValid = true

        // if (!data.name) {
        //     formIsValid = false
        //     errors['name'] = "กรุณากรอกชื่อ";
        // }

        // if (!data.phone || data.phone.length != 10 || isNaN(data.phone)) {
        //     formIsValid = false
        //     errors['phone'] = "กรุณากรอกหมายเลขโทรศัพท์ให้ถูกต้อง";
        // }


        if (!data.bankName) {
            formIsValid = false
            errors['bankName'] = "กรุณาเลือกธนาคาร";
        }
        if (!data.bankNumber || isNaN(data.bankNumber)) {
            formIsValid = false
            errors['bankNumber'] = "กรุณากรอกเลขบัญชีให้ถูกต้อง";
        }

        if (!data.bankAccountName) {
            formIsValid = false
            errors['bankAccountName'] = "กรุณากรอกชื่อบัญชี";
        }

        if (!data.bankAccountNameEN) {
            formIsValid = false
            errors['bankAccountNameEN'] = "กรุณากรอกชื่อบัญชี";
        }

        const pattern = /^[a-zA-Zก-๏]{2,40}( [a-zA-Zก-๏]{2,40})+$/;
        if (!pattern.test(data.bankAccountName)) {
            formIsValid = false
            errors['bankAccountName'] = "ชื่อบัญชีไม่ถูกต้อง ตรวจสอบการเว้นวรรค 1 วรรคเท่านั้น";
        }
        if (!pattern.test(data.bankAccountNameEN)) {
            formIsValid = false
            errors['bankAccountNameEN'] = "ชื่อบัญชีไม่ถูกต้อง ตรวจสอบการเว้นวรรค 1 วรรคเท่านั้น";
        }
        // if (!pattern.test(data.name)) {
        //     formIsValid = false
        //     errors['name'] = "ชื่อ-นามสกุล ไม่ถูกต้อง";
        // }
        setErrors(errors)
        return formIsValid
    }

    const handleUpdate = (e) => {
        e.preventDefault();
        if (handleValidation()) {
            setSubmit(true)
            // console.log(data)
            updateProfile(data)
        }
    }


    return (
        <>
            {/*<Breadcrumb>*/}
            {/*    <Breadcrumb.Item href="#">หน้าแรก</Breadcrumb.Item>*/}
            {/*    <Breadcrumb.Item href="/account">ข้อมูลส่วนตัว</Breadcrumb.Item>*/}
            {/*    <Breadcrumb.Item active>แก้ไขข้อมูลส่วนตัว</Breadcrumb.Item>*/}
            {/*</Breadcrumb>*/}

            <div className="heading-title">
                <div>
                    <h3>ข้อมูลส่วนตัว</h3>
                    <div>แก้ไขข้อมูลส่วนตัว</div>
                </div>
                <Link to="/account" className="back">
                    <FaArrowLeft/> ย้อนกลับ
                </Link>
            </div>

            <Credit profile={profile} />

            <div className="row">
                <div className="col-lg-12">
                    <div className="box nor position-relative mb-4">
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <div className="p-4">
                                    <form onSubmit={e => handleUpdate(e)}>
                                        <div className="heading line-o h4 mb-4">
                                            <i>แก้ไขข้อมูล</i>
                                        </div>

                                        {/*{actionStatus == "success" && (*/}
                                        {/*    <div className="alert alert-success">แก้ไขข้อมูลสำเร็จ</div>)}*/}
                                        {/*{actionStatus == "error" && (*/}
                                        {/*    <div className="alert alert-danger">ไม่สามารถแก้ไขข้อมูลได้ กรุณาลองใหม่อีกครั้ง</div>)}*/}

                                        {/*<div className="form-group">*/}
                                        {/*    <label><i>ชื่อ-นามสกุล</i></label>*/}
                                        {/*    <input type="text" disabled={hasBank} autoComplete="new-password" onChange={(e) => handleInput(e)} value={data.name} name="name" placeholder="ชื่อ" className="form-control "/>*/}
                                        {/*    <div className="text-danger"><small>{errors["name"]}</small></div>*/}
                                        {/*</div>*/}
                                        {/*<div className="form-group">*/}
                                        {/*    <label><i>หมายเลขโทรศัพท์</i></label>*/}
                                        {/*    <input type="text" disabled={hasBank} autoComplete="new-password" onChange={(e) => handleInput(e)} value={data.phone} name="phone" placeholder="หมายเลขโทรศัพท์" className="form-control "/>*/}
                                        {/*    <div className="text-danger"><small>{errors["phone"]}</small></div>*/}
                                        {/*</div>*/}

                                        <div className="form-group">
                                            <label><i>ธนาคาร</i></label>
                                            <select className="form-control" disabled={hasBank} name="bankName" onChange={(e) => handleInput(e)}>
                                                <option>เลือกธนาคาร</option>
                                                {bank.map(item => (
                                                    <option value={item.code} selected={data.bankName == item.code}>{item.name}</option>
                                                ))}
                                            </select>
                                            <div className="text-danger"><small>{errors["bankName"]}</small></div>
                                        </div>
                                        <div className="form-group">
                                            <label><i>เลขบัญชี</i></label>
                                            <input type="text" disabled={hasBank} autoComplete="new-password" onChange={(e) => handleInput(e)} value={data.bankNumber} name="bankNumber" placeholder="เลขบัญชี" className="form-control "/>
                                            <div className="text-danger"><small>{errors["bankNumber"]}</small></div>
                                        </div>
                                        <div className="form-group">
                                            <label><i>ชื่อบัญชี (ไทย)</i></label>
                                            <input type="text" disabled={hasBank} autoComplete="new-password" onChange={(e) => handleInput(e)} value={data.bankAccountName} name="bankAccountName" placeholder="ชื่อบัญชี (ไทย)" className="form-control "/>
                                            <div className="text-danger"><small>{errors["bankAccountName"]}</small>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label><i>ชื่อบัญชี (English)</i></label>
                                            <input type="text" disabled={hasBank} autoComplete="new-password" onChange={(e) => handleInput(e)} value={data.bankAccountNameEN} name="bankAccountNameEN" placeholder="ชื่อบัญชี (English)" className="form-control "/>
                                            <div className="text-danger"><small>{errors["bankAccountNameEN"]}</small>
                                            </div>
                                        </div>

                                        {!hasBank ? (
                                            <>
                                                {submitted ? (
                                                    <button disabled={true} className="btn btn-primary btn-lg btn-block">รอสักครู่</button>) : (
                                                    <button onClick={e => handleUpdate(e)} className="btn btn-primary btn-lg btn-block">บันทึก</button>)}
                                            </>
                                        ) : (
                                            <button disabled={true} className="btn btn-primary btn-lg btn-block">กรุณาติดต่อทีมงานเพื่อขอแก้ไขข้อมูล</button>
                                        )}


                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default EditProfile
