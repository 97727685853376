import {withRouter} from "react-router-dom";
import ProviderGaming from "./ProviderGaming";
import React, {useContext, useEffect} from "react";
import {AccountContext} from "../../context/AccountContext";

const Play = () => {
    const {setMenuActive} = useContext(AccountContext)
    useEffect(() => {
        setMenuActive('game')
    }, [])
    return (
       <>
           <ProviderGaming size="lg"/>
       </>
    )
}


export default withRouter(Play)
