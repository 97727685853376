import axios from "axios";
import LocalStorageService from "./services/LocalStorageService";
import {App} from "./config/App";


const localStorageService = LocalStorageService.getService();

export const API_TOKEN = {
    accessToken: localStorageService.getAccessToken(),
    refreshToken: localStorageService.getRefreshToken(),
}

export const axioClientBot = axios.create({
    // baseURL: "https://bo.igamesea.com/api",
    baseURL: App.baseURLBE,
    // baseURL: "https://office.phenix295.com/api",
    // baseURL: `https://ag.168lionbet.com/api`,
});

const axioClient = axios.create({
    // baseURL: "https://api.igamesea.com",
    baseURL: App.baseURL,
    // baseURL: `https://api.phenix295.com`,
    // baseURL: "http://127.0.0.1:8881",
});


axioClient.interceptors.request.use(
    config => {
        const token = localStorageService.getAccessToken();
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        config.headers['Content-Type'] = 'application/json';
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

axioClient.interceptors.response.use((response) => {
    return response;
}, function (error) {
    const originalRequest = error.config;


    if (error.response.status === 401 && originalRequest.url == `${App.enpoint}/user/refresh`) {
        console.log("Error");
        localStorageService.clearToken();
        window.location.href = "/login";
        return Promise.reject(error);
    }


    // console.log(originalRequest.url)

    if (error.response.status === 401 && !originalRequest._retry && originalRequest.url !== `/user/login`) {
        originalRequest._retry = true;
        // const refreshToken = localStorageService.getRefreshToken();
        const data = {
            "refreshToken": localStorageService.getRefreshToken(),
            "accessToken": localStorageService.getAccessToken(),
        };
        return axioClient.post(`${App.enpoint}/user/refresh`, data).then(response => {
            // console.log(response.data);
            if (response.status === 200) {
                localStorageService.setToken(response.data);
                axioClient.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageService.getAccessToken();
                return axioClient(originalRequest);
            }
        }).catch(e => {
            // alert(555)
            if (localStorageService.getUniversalLogin() == 1) {
                window.location.href = "/ulogin";
            } else {
                window.location.href = "/login";
            }

            return Promise.reject(error);
        })
    }

    return Promise.reject({
        status: error.response.status,
        error: error
    });
});


export default axioClient;
