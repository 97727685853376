import React, {createContext, useReducer} from "react";
import GameReducer from "../reducers/GameReducer";
import {LoginContext} from "./LoginContext";
import GameService from "../services/GameService";
import {NotificationManager} from "react-light-notifications";

const initialState = {
    games: [],
    provider: null,
    page: 0,
    pageSize: 0,
    total: 0,
    onOpen: false,
    url: null
}
export const GameContext = createContext(initialState);
export const GameProvider = ({children}) => {
    const [state, dispatch] = useReducer(GameReducer, initialState);

    function getGames(provider, page, setLoaded) {
        GameService.getGames(provider, page).then(resp => {
            dispatch({
                type: 'FETCH',
                payload: resp.data
            })

        }).catch(e => {
            dispatch({
                type: 'NO_DATA',
            })
        }).finally(() => {
            setOnOpen(false)
            if (typeof setLoaded != 'undefined')
                setLoaded(true)
        })
    }

    function lunchGame(id, code, mobile) {
        setOnOpen(true)
        GameService.lunchGame(id, code, mobile).then(resp => {
            const data = resp.data
            if (typeof data.url != 'undefined' && data.url != "") {
                // if (mobile) {
                //     window.location.href = data.url
                // } else {
                //     window.open(data.url, "_blank")
                // }

                if (id == `PGSOFT` || id == `SLOTXO`) {
                    if(mobile) {
                        window.location.href = data.url
                        return;
                    }
                }
                setOnOpen(false)
                dispatch({
                    type: 'OPEN_GAME',
                    payload: data.url
                })
            } else {
                NotificationManager.error({
                    title: 'ผิดพลาด',
                    message: "Service unavailable",
                });
                // alert("Service unavailable")
            }
        }).catch(e => {
            NotificationManager.error({
                title: 'ผิดพลาด',
                message: "Service unavailable",
            });
            // alert("Service unavailable")
        }).finally(() => {
            setOnOpen(false)
        })
    }

    function clearState() {
        dispatch({
            type: 'NO_DATA',
        })
    }

    function setOnOpen(status) {
        dispatch({
            type: 'SET_OPEN_GAME',
            payload: status
        })
    }

    function closeGame(){
        dispatch({
            type: 'CLOSE_GAME'
        })
    }

    return (
        <GameContext.Provider value={{
            gameState: state,
            games: state.games,
            openGameUrl:state.url,
            closeGame,
            getGames,
            lunchGame,
            clearState
        }}>
            {children}
        </GameContext.Provider>
    );
}
