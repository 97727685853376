import React, {useEffect, useState} from "react";
import GameItems from "./GameItems";
import {Breadcrumb} from "react-bootstrap";
import {Link, useHistory} from "react-router-dom";
import {FaArrowLeft} from "react-icons/all";


const Pgsoft = () => {
    const history = useHistory();
    const styles = {
        backgroundImage: `url('/assets/images/cover/m_pgslot.png')`
    }

    return (
        <>
            <div className="cover" style={styles}>
                <div className="heading-title sm">
                    <div>
                        <h3>
                            <span className="d-lg-none d-inline">สล็อต / เกม</span>
                            <span className="d-none d-lg-inline">PGSLOT</span>
                        </h3>
                        <div>
                            <span className="d-lg-none d-inline">SLOT GAMING</span>
                            <span className="d-none d-lg-inline">รายชื่อเกมส์ที่เปิดให้บริการทั้งหมด</span>
                        </div>
                    </div>
                    <Link onClick={history.goBack} className="back text-white pl-3 py-3 pr-0">
                        <FaArrowLeft/> ย้อนกลับ
                    </Link>
                </div>
                <div className="category">
                    <div className="inner">
                        <div className="h1 mb-0">PGSLOT</div>
                        <p className="mb-0 text-warning">รายชื่อเกมส์ที่เปิดให้บริการทั้งหมด</p>
                    </div>
                </div>
            </div>
            {/*<div className="text-center mb-5"><h1>PGSLOT</h1></div>*/}
            <GameItems provider="PGSOFT" pathFolder={'/assets/images/games/pg/'} extionsion={`.jpg`} size="sm"/>
        </>
    )


}


export default Pgsoft
