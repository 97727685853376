export default function GameReducer(state = [], action) {
    switch (action.type) {
        case 'FETCH':
            return {
                ...state,
                games: action.payload.games,
                page: action.payload.page,
                pageSize: action.payload.pageSize,
                total: action.payload.total,
            }
        case 'NO_DATA':
            return {
                ...state,
                games: [],
                page: 0,
                pageSize: 0,
                total: 0,
                onOpen: false,
            }
        case 'SET_OPEN_GAME':
            return {
                ...state,
                onOpen: action.payload,
            }
        case 'OPEN_GAME' :
            return {
                ...state,
                url: action.payload
            }
        case 'CLOSE_GAME' :
            return {
                ...state,
                url: null
            }
    }
}
