import React, {useContext, useEffect, useState} from "react";
import {GameContext} from "../../context/GameContext";
import Paginate from "../Paginate/Paginate";
import {Spinner} from "react-bootstrap";
import {isMobile} from 'react-device-detect';
import {FaTimes} from "react-icons/all";
import {AccountContext} from "../../context/AccountContext";
import {Modal, Button} from 'react-bootstrap';

const GameItems = ({provider, size, pathFolder, extionsion, image = `local`, imageformat = `placeholder`}) => {
    const [mobile, setMobile] = useState(isMobile)
    const [loaded, setLoaded] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const {getGames, games, lunchGame, gameState, clearState, openGameUrl, closeGame} = useContext(GameContext)
    const {getProfile, reloadProfile} = useContext(AccountContext)

    useEffect(() => {
        clearState()
        getGames(provider, 1, setLoaded)
    }, [provider])

    const handleLunchGame = (code, name) => {
        // console.log(mobile)

        // alert('ระบบเกมปรับปรุง')
        //setShowModal(true)
        //return

        let text = name.toLowerCase()
        if (text.indexOf('fish') !== -1)
            alert('Service Unavailable')
        else {
            lunchGame(provider, code, mobile)
        }
    }

    const handleClosePopup = () => {
        getProfile()
        closeGame()
    }

    const imageName = (name) => {
        const ext = typeof extionsion == `undefined` ? `.png` : extionsion

        const a = pathFolder + name.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '').replace(/---/g, '-').replace(/--/g, '-') + ext
        // console.log(a)
        return a
    }

    if (gameState.total <= 0 && !loaded) {
        return (
            <div className="open-game-popup">
                <div className="text-center">
                    <Spinner animation="border" variant="danger"/>
                    <div className="text-white mt-3">กำลังโหลดข้อมูล...</div>
                </div>
            </div>
        )
    }
    const addDefaultSrc = (ev, defaultImage = ``) => {
        if (defaultImage !== ``) {
            ev.target.src = defaultImage
        } else {
            ev.target.src = '/assets/images/games/default.png?a=1'
        }
    }

    return (
        <>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark">แจ้งเตือน</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="alert alert-warning text-center h4 mb-0">ระบบเกมส์ปิดปรับปรุง</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        ปิดหน้าต่าง
                    </Button>
                </Modal.Footer>
            </Modal>

            {gameState.onOpen && (
                <div className="open-game-popup">
                    <div className="text-center">
                        <Spinner animation="border" variant="danger"/>
                        <div className="text-white mt-3">กำลังโหลดข้อมูล...</div>
                    </div>
                </div>
            )}

            {openGameUrl && (
                <div className="open-game-popup-frm">
                    <button className="close-game" onClick={() => handleClosePopup()}><FaTimes/></button>
                    <iframe src={openGameUrl} frameborder="0"></iframe>
                </div>
            )}


            {gameState.total > 0 ? (
                <>
                    <div className={`row row-ssm justify-content-center ${imageformat === `icon` ? `align-items-end` : ``}`}>
                        {games && games.map((item, index) => (

                            <div key={index} className={"mb-1 " + (size == 'lg' ? 'col-12 col-sm-6  col-lg-4' : (size == 'xl' ? 'col-lg-6' : 'col-xl-2 col-lg-2 col-sm-3 col-4 '))}>
                                <div className="game-item " onClick={() => handleLunchGame(item.shortName, item.name)}>
                                    {image === `provider` ? (
                                        <>
                                            {imageformat === `placeholder` ? (
                                                <div className="img-card-placeholder  img-card-s3">
                                                    <div className="image" style={{backgroundImage: `url(${item.img})`}}></div>
                                                </div>
                                            ) : (
                                                <img src={item.img} alt={item.name} onError={e => addDefaultSrc(e)} className="w-100"/>
                                            )}

                                        </>
                                    ) : (
                                        <>
                                            <div className="mb-2" data-img={item.img}>
                                                <img src={imageName(item.name)} alt={item.name} onError={e => addDefaultSrc(e)} className="w-100"/>
                                            </div>
                                            <div className="text-center name">{item.name}</div>
                                        </>
                                    )}


                                </div>
                            </div>

                        ))}
                    </div>
                </>
            ) : (
                <>
                    {loaded && (<div className="text-center text-muted h3"><i>ไม่พบเกมส์ เปิดให้บริการ</i></div>)}
                </>
            )}


            {gameState.total > 0 && (
                <Paginate getGames={getGames} provider={provider} page={gameState.page} totalRecords={gameState.total} pageLimit={gameState.pageSize} pageNeighbours={1}/>
            )}


        </>
    )
}
export default GameItems
