import React, {useEffect, useState} from "react";
import GameItems from "./GameItems";
import {Breadcrumb} from "react-bootstrap";
import {Link, useHistory} from "react-router-dom";
import {FaArrowLeft} from "react-icons/all";

const Live22 = () => {
    const history = useHistory();
    const styles = {
        backgroundImage: `url('/assets/images/cover/m_live22.png')`
    }

    return (
        <>
            <div className="cover" style={styles}>
                <div className="heading-title sm">
                    <div>
                        <h3>
                            <span className="d-lg-none d-inline">คาสิโนสด</span>
                            <span className="d-none d-lg-inline">Live22</span>
                        </h3>
                        <div>
                            <span className="d-lg-none d-inline">LIVE CASINO</span>
                            <span className="d-none d-lg-inline">รายชื่อเกมส์ที่เปิดให้บริการทั้งหมด</span>
                        </div>
                    </div>
                    <Link onClick={history.goBack} className="back pl-3 py-3 pr-0 text-white">
                        <FaArrowLeft/> ย้อนกลับ
                    </Link>
                </div>
                <div className="category">
                    <div className="inner">
                        <div className="h1 mb-0">Live22</div>
                        <p className="mb-0 text-warning">รายชื่อเกมส์ที่เปิดให้บริการทั้งหมด</p>
                    </div>
                </div>
            </div>

            {/*<Breadcrumb>*/}
            {/*    <Breadcrumb.Item href="#">หน้าแรก</Breadcrumb.Item>*/}
            {/*    <Breadcrumb.Item active>LIVE22</Breadcrumb.Item>*/}
            {/*</Breadcrumb>*/}
            {/*<div className="text-center mb-5"><h1>Live22</h1></div>*/}
            <GameItems provider="LIVE22" pathFolder={'/assets/images/games/live22/'} extionsion={`.jpg`} size="sm"/>

        </>
    )


}


export default Live22
