import logo168 from "../assets/images/logo.png"
import logoPhenix from "../assets/images/logo-phenix.png"
import logoUfo89 from "../assets/images/logo-ufo89.png"
import logoNos888 from "../assets/images/logo-nos888.png"
import qrPhenix from "../assets/images/qr-phenix.jpg"


const app168lion = {
    app_name: `168lion`,
    prefix: `LION`,
    baseURL: `https://ag.168lionbet.com`,
    baseURLBE: `https://bo.slotautospin.com/api/`,
    enpoint: `/api/v2`,
    logo: logo168,
    gameSport: process.env.REACT_APP_SPORT_GAME,
    lineUrl: process.env.REACT_APP_LINE_URL,
    lineQrcode: ``,
    lineId: `@168lion`,
    css : ``
}

const appPhenix = {
    app_name: `Phenix295`,
    prefix: `PHEN`,
    baseURL: `https://api.phenix295.com`,
    // baseURL: `http://127.0.0.1:8881`,
    baseURLBE: `https://office.phenix295.com/api/`,
    enpoint: ``,
    logo: logoPhenix,
    gameSport: process.env.REACT_APP_SPORT_GAME,
    lineUrl: `https://lin.ee/ZiHLVA6`,
    lineQrcode: qrPhenix,
    lineId: `@phenix295`,
    css : `./style.gold.css`
}

const appNos888 = {
    app_name: `NOS888`,
    prefix: `NOS8`,
    baseURL: `https://ag.168lionbet.com`,
    baseURLBE: `https://bo.slotautospin.com/api/`,
    enpoint: `/api/v2`,
    logo: logoNos888,
}

const appUfo89 = {
    app_name: `UFO89BET`,
    prefix: `UFOB`,
    baseURL: `https://ag.168lionbet.com`,
    baseURLBE: `https://bo.slotautospin.com/api/`,
    enpoint: `/api/v2`,
    logo: logoUfo89,
}


const appDemo = {
    app_name: `UFO89BET`,
    prefix: `UFOB`,
    baseURL: `http://127.0.0.1:8881`,
    baseURLBE: `https://bo.slotautospin.com/api/`,
    enpoint: ``,
    logo: logoUfo89,
}


export const App = app168lion
