import React, {createContext, useReducer} from "react";
import AccountReducer from "../reducers/AccountReducer";
import UserService from "../services/UserService";
import {NotificationManager} from "react-light-notifications";


const initialState = {
    profile: {},
    reloadProfile: false,
    agentBank: [],
    actionStatus: null,
    submitted: false,
    menuActive: null,
    childs: {},
}


export const AccountContext = createContext(initialState);

export const AccountProvider = ({children}) => {
    const [state, dispatch] = useReducer(AccountReducer, initialState);

    function getProfile() {
        dispatch({
            type: 'RELOAD_PROFILE',
            payload: true
        })
        UserService.getProfile().then(resp => {
            // console.log(resp.data)
            let profile = resp.data
            profile.updateTime = new Date()
            dispatch({
                type: 'GET_PROFILE',
                payload: profile
            })
            dispatch({
                type: 'RELOAD_PROFILE',
                payload: false
            })
        }).catch(e => {
            dispatch({
                type: 'RELOAD_PROFILE',
                payload: false
            })
            // console.log(e)
        })
    }


    function updateProfile(data) {
        UserService.updateProfile(data).then(resp => {
            // dispatch({
            //     type: 'GET_PROFILE',
            //     payload: resp.data
            // })
            dispatch({
                type: 'ACTION_STATUS',
                payload: "updated"
            })
            NotificationManager.success({
                title: 'สำเร็จ',
                message: "แก้ไขข้อมูลสำเร็จ",
            });


            window.location.href = "/deposit"

        }).catch((e) => {
            // console.log(e)
            dispatch({
                type: 'ACTION_STATUS',
                payload: "error"
            })
            NotificationManager.error({
                title: 'ผิดพลาด',
                message: "กรุณาลองใหม่อีกครั้ง",
            });
        })
    }

    function getAffChild(prefix, page) {
        UserService.getChilds(prefix, page).then(resp => {
            dispatch({
                type: 'GET_CHILDS',
                payload: resp.data
            })
        })
    }

    function changePassword(data) {
        UserService.changePassword(data).then(resp => {
            dispatch({
                type: 'ACTION_STATUS',
                payload: "success"
            })
            NotificationManager.success({
                title: 'สำเร็จ',
                message: "เปลี่ยนรหัสผ่านสำเร็จ",
            });
        }).catch(() => {
            dispatch({
                type: 'ACTION_STATUS',
                payload: "error"
            })
            NotificationManager.error({
                title: 'ผิดพลาด',
                message: "เปลี่ยนรหัสผ่านสำเร็จ ไม่สำเร็จ",
            });
        }).finally(() => {
            setSubmit(false)
        })
    }

    function getAgentBank() {
        UserService.getAgentBank().then(resp => {
            // console.log(resp.data)
            dispatch({
                type: 'GET_AGENT_BANK',
                payload: resp.data
            })
        }).catch(e => {
            // console.log(e)
        })
    }

    function addWithdraw(data) {
        UserService.withdraw(data).then(resp => {
            dispatch({
                type: 'ACTION_STATUS',
                payload: "success"
            })
            NotificationManager.success({
                title: 'สำเร็จ',
                message: "แจ้งถอนสำเร็จ กรุณารอทำรายการ",
            });
        }).catch(e => {
            dispatch({
                type: 'ACTION_STATUS',
                payload: "error"
            })
            if (typeof e.status != 'undefined' && e.status === 422) {
                NotificationManager.error({
                    title: 'ผิดพลาด',
                    message: "ไม่สามารถทำรายการได้ ไอดีของคุณติดเงื่อนไขโปรโมชั่น",
                });
            } else {
                NotificationManager.error({
                    title: 'ผิดพลาด',
                    message: "ทำรายการ ไม่สำเร็จ",
                });
            }
            // console.log(e)
        })
    }

    function setTogglePromotion() {
        UserService.togglePromotion().then(resp => {
            NotificationManager.success({
                title: 'สำเร็จ',
                message: "เปลี่ยนแปลงสถานะรับโปรโมชั้นสำเร็จ",
            });
            getProfile()
        }).catch(e => {
            NotificationManager.error({
                title: 'ไม่สำเร็จ',
                message: "ไม่สามารถเปลี่ยนแปลงสถานะรับโปรโมชั้นได้",
            });
        })
    }


    function resetActionStatus() {
        dispatch({
            type: 'ACTION_STATUS',
            payload: null
        })
    }

    function setSubmit(status) {
        dispatch({
            type: 'SUBMIT',
            payload: status
        })
    }


    function setMenuActive(menu) {
        dispatch({
            type: 'ACTIVE_MENU',
            payload: menu
        })
    }

    return (
        <AccountContext.Provider value={{
            profile: state.profile,
            reloadProfile: state.reloadProfile,
            agentBank: state.agentBank,
            actionStatus: state.actionStatus,
            submitted: state.submitted,
            menuActive: state.menuActive,
            childs: state.childs,
            getProfile,
            getAgentBank,
            updateProfile,
            changePassword,
            resetActionStatus,
            setSubmit,
            addWithdraw,
            setMenuActive,
            getAffChild,
            setTogglePromotion
        }}>
            {children}
        </AccountContext.Provider>
    );

}

