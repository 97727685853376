import {FaBitcoin, FaChartLine, FaDashcube, FaGamepad, FaHistory, FaHome, FaWallet} from "react-icons/all";
import {Link} from "react-router-dom";
import {useContext, useEffect} from "react";
import {AccountContext} from "../../context/AccountContext";

const Footer = () => {
    const {menuActive} = useContext(AccountContext)
    // useEffect(() => {
    //     console.log(menuActive)
    // }, [menuActive])
    return (
        <>
            <div className="footer-menu">
                <div className="row py-3 justify-content-center">
                    <div className="col-lg-8">
                        <div className="row justify-content-center">
                            <div className="col text-center">
                                <Link to="/games" className={menuActive == `game` ? 'text-warning' : ''}>
                                    <FaGamepad/>
                                    <div>เล่นเกมส์</div>
                                </Link>
                            </div>
                            <div className="col text-center">
                                <Link to="/dashboard" className={menuActive == `dashboard` ? 'text-warning' : ''}>
                                    <FaBitcoin className=""/>
                                    <div>กระเป๋าตัง</div>
                                </Link>
                            </div>
                            <div className="col text-center">
                                <Link to="/bet-history" className={menuActive == `bet-history` ? 'text-warning' : ''}>
                                    <FaChartLine/>
                                    <div>ประวัติเดิมพัน</div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )


}


export default Footer
