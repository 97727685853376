import React, {cloneElement} from 'react';
import {LoginProvider} from "./LoginContext";
import {MenuActiveProvider} from "./MenuActiveContext";
import {AccountProvider} from "./AccountContext";
import {GameProvider} from "./GameContext";
import {ToastProvider} from "react-toast-notifications";
import {HistoryProvider} from "./HistoryContext";


function ProviderComposer({contexts, children}) {
    return contexts.reduce(
        (kids, parent) =>
            cloneElement(parent, {
                children: kids,
            }),
        children
    );
}

export default function ProviderContext({children}) {
    return (
        <ProviderComposer
            contexts={[
                <ToastProvider/>,
                <LoginProvider/>,
                <MenuActiveProvider/>,
                <AccountProvider/>,
                <GameProvider/>,
                <HistoryProvider/>,
            ]}
        >{children}</ProviderComposer>
    );
}
