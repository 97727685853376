import {FaBars, FaRegUserCircle, FiRepeat, IoReload} from "react-icons/all";
import React, {useContext, useState} from "react";
import {MenuActiveContext} from "../../context/MenuActiveContext";
import CurrencyFormat from "react-currency-format";
import logo from "../../assets/images/logo.png"
import {AccountContext} from "../../context/AccountContext";
import {App} from "../../config/App";

const Header = ({profile}) => {
    const [open, setOpen] = useState(false);
    const {menuCollapse} = useContext(MenuActiveContext)
    const {getProfile, reloadProfile} = useContext(AccountContext)

    const handleOpen = (status) => {
        // console.log("status : " + status)
        // const op = status
        setOpen(status)
        menuCollapse(status)
    }

    const handleProfile = (event) => {
        event.preventDefault()
        getProfile()
    }

    return (
        <header className="header shadow">
            <nav className="navbar navbar-expand-lg">
                <a className="nav-link" href="/account">
                    <FaRegUserCircle/>
                    {/*{profile && profile.username}*/}
                </a>
                <a className="navbar-brand mr-lg-0" href="/dashboard">
                    <img src={App.logo} alt=""/>
                </a>
                <button className="navbar-toggler" type="button" onClick={() => handleOpen(true)}>
                    <FaBars/>
                </button>

                <div className="collapse navbar-collapse">
                    {/*<div className="form-inline mr-auto">*/}
                    {/*    <div className="input-group">*/}
                    {/*        <input type="text" className="form-control" placeholder="ค้นหา เกมส์,คาสิโน,กีฬา,สล็อต"/>*/}
                    {/*        <div className="input-group-append">*/}
                    {/*            <button className="btn btn-outline-secondary" type="button">ค้นหา</button>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item d-flex align-items-center">
                            <a href="#" className={reloadProfile ? "icon-spin" : ""} onClick={e => handleProfile(e)}>
                                <IoReload/>
                            </a>
                            <a className="nav-link" onClick={e => handleProfile(e)} href="/dashboard">
                                ยอดเครดิต <CurrencyFormat value={ reloadProfile ? 0 : ( profile && profile.balance ? profile.balance : 0) } displayType={'text'} suffix=" บาท" thousandSeparator={true}/>
                            </a>

                        </li>
                        {/*<li className="nav-item">*/}
                        {/*    <a className="nav-link" href="/account"><FaRegUserCircle/> {profile && profile.username}</a>*/}
                        {/*</li>*/}
                    </ul>
                </div>
            </nav>
        </header>
    );
}


export default Header
