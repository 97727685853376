import {Breadcrumb} from "react-bootstrap";
import Pagination from "react-js-pagination";
import React, {useContext, useEffect, useState} from "react";
import ProviderGaming from "../Games/ProviderGaming";
import {HistoryContext} from "../../context/HistoryContext";
import {AccountContext} from "../../context/AccountContext";
import CurrencyFormat from "react-currency-format";
import Moment from "react-moment";
import {Link} from "react-router-dom";
import {FaArrowLeft, FaClock} from "react-icons/all";
import Credit from "./Credit";
import moment from "moment";
import DatePicker from "react-datepicker";


const BetHistory = () => {

    const {profile, setMenuActive} = useContext(AccountContext)
    // const [data, setData] = useState()
    const [date, setDate] = useState({
        start: new Date(moment().format('YYYY-MM-DD')),
        end: new Date(moment().format('YYYY-MM-DD')),
    })
    const {historyState, getHistory, historyData} = useContext(HistoryContext)

    const [activePage, setActivePage] = useState(1)
    const [loading, setLoading] = useState(1)

    useEffect(() => {
        if (typeof profile.username != "undefined") {
            getHistory(profile.prefix, profile.username, moment(date.start).format("YYYY-MM-DD"), activePage)
            setLoading(0)
        }
    }, [profile])

    // const {setMenuActive} = useContext(AccountContext)
    useEffect(() => {
        setMenuActive('bet-history')
    }, [])


    const handlePageChange = (pageNumber) => {
        console.log(`active page is ${pageNumber}`);
        setActivePage(pageNumber);
        getHistory(profile.prefix, profile.username, '', pageNumber)
    }

    const setStartDate = (v, type) => {
        // console.log(moment().format('YYYY-MM-DD'))
        if (type == 'start')
            setDate({
                ...date,
                start: v
            })
        else
            setDate({
                ...date,
                end: v
            })
    }

    const handleSearch = () => {
        setActivePage(1);
        getHistory(profile.prefix, profile.username, moment(date.start).format("YYYY-MM-DD"), 1)
    }


    return (
        <>

            <div className="heading-title">
                <div>
                    <h3>ประวัติการวางเดิมพัน</h3>
                    <div>รายการวางเดิมย้อนหลัง</div>
                </div>
                <Link to="/dashboard" className="back">
                    <FaArrowLeft/> ย้อนกลับ
                </Link>
            </div>

            <Credit profile={profile} />

            <div className="row">
                <div className="col-lg-12 col-lg-c9">
                    <div className="nor position-relative mb-4">
                        <div className="p-0">

                            <div className="form-inline  mb-4">
                                <div className="form-group w-sm-100 mr-2">
                                    <span className="mr-2">วันที่เริ่มต้น</span>
                                    <DatePicker dateFormat="yyyy-MM-dd" selected={date.start} className="form-control" onChange={(date) => setStartDate(date, 'start')}/>
                                </div>
                                <div className="mt-lg-0 pt-lg-0 pt-sm-2 mt-sm-3 mr-4 w-sm-100">
                                    <button className="btn btn-success btn-block btn-sm" onClick={handleSearch}>ค้นหา</button>
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    <div className="table-responsive">
                                        <table className="table table-custom table-striped">
                                            <thead>
                                            <tr className="d-none d-lg-table-row">
                                                <th width={200} className="text-center border-top-0">
                                                    <div className="h6 m-0 text-white"><i>วันที่</i></div>
                                                </th>
                                                <th width={200} className="text-right border-top-0">
                                                    <div className="h6 m-0 text-white"><i>เดิมพัน</i></div>
                                                </th>
                                                <th width={150} className=" border-top-0">
                                                    <div className="h6 m-0 text-white"><i>เกมส์</i></div>
                                                </th>
                                                <th className=" border-top-0">
                                                    <div className="h6 m-0 text-white"><i>ประเภท</i></div>
                                                </th>
                                                <th width={120} className="text-right border-top-0">
                                                    <div className="h6 m-0 text-white"><i>Win</i></div>
                                                </th>
                                                <th width={120} className="text-right border-top-0">
                                                    <div className="h6 m-0 text-white"><i>Winloss</i></div>
                                                </th>
                                            </tr>
                                            <tr className=" d-none">
                                                <th><div className="h6 m-0 text-white"><i>รายละเอียด</i></div></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {historyData && historyData.length > 0 ? (
                                                <>
                                                    {historyData.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className="text-left text-lg-center">
                                                                <div className="d-block d-lg-none">
                                                                    <div className="d-flex justify-content-between">
                                                                        <h5 className="text-white"><i>{item.provider}</i></h5>
                                                                        <div className="text-right">
                                                                            ประเภท {item.betType}
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mb-3">
                                                                        <div className="text-danger">เดิมพัน <CurrencyFormat value={item.bet} displayType={'text'} thousandSeparator={true}/></div>
                                                                        <div className="text-info">Win  <CurrencyFormat value={item.win} displayType={'text'} thousandSeparator={true}/></div>
                                                                        <div className={`text-` + (item.winloss > 0 ? `success` : `danger` )}>WinLoss  <CurrencyFormat value={item.winloss} displayType={'text'} thousandSeparator={true}/></div>
                                                                    </div>
                                                                    <small className="d-flex align-items-center">
                                                                        <FaClock className="mr-2"/> <Moment format="YYYY/MM/DD hh:mm">{item.time}</Moment>
                                                                    </small>
                                                                </div>
                                                                <div className="d-lg-block d-none">
                                                                    <Moment format="YYYY/MM/DD hh:mm">{item.time}</Moment>
                                                                </div>

                                                            </td>
                                                            <td className="text-right d-lg-table-cell d-none">
                                                                <CurrencyFormat value={item.bet} displayType={'text'} thousandSeparator={true}/>
                                                            </td>
                                                            <td className="text-center d-lg-table-cell d-none">{item.provider}</td>
                                                            <td className=" d-lg-table-cell d-none">{item.betType}</td>
                                                            <td className="text-right d-lg-table-cell d-none">
                                                                <CurrencyFormat value={item.win} displayType={'text'} thousandSeparator={true}/>
                                                            </td>
                                                            <td className="text-right d-lg-table-cell d-none">
                                                                <CurrencyFormat value={item.winloss} displayType={'text'} thousandSeparator={true}/>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </>
                                            ) : (
                                                <tr>
                                                    <td colSpan={6} className="text-center">ไม่พบข้อมูล</td>
                                                </tr>
                                            )}

                                            </tbody>
                                        </table>
                                    </div>
                                    {historyData && historyData.length > 0 && (
                                        <div className="d-flex justify-content-center">
                                            <Pagination
                                                itemClass={"page-item"}
                                                linkClass={"page-link"}
                                                activePage={activePage}
                                                itemsCountPerPage={historyState.pageSize}
                                                totalItemsCount={historyState.total ? historyState.total : 0}
                                                pageRangeDisplayed={5}
                                                onChange={e => handlePageChange(e)}
                                            />
                                        </div>
                                    )}

                                </div>
                            </div>


                        </div>
                    </div>

                    <ProviderGaming/>

                </div>
            </div>
        </>
    )
}

export default BetHistory
