const LocalStorageService = (function () {
    var _service;

    function _getService() {
        if (!_service) {
            _service = this;
            return _service
        }
        return _service
    }

    function _setToken(tokenObj) {
        localStorage.setItem('accessToken', tokenObj.accessToken);
        localStorage.setItem('refreshToken', tokenObj.refreshToken);
    }

    function _setPrefix(prefix) {
        localStorage.setItem('prefix', prefix);
    }

    function _setUniversalLogin(universal) {
        localStorage.setItem('universalLogin', universal);
    }

    function _setMe(data) {
        localStorage.setItem('me', JSON.stringify(data));
    }

    function _setRef(ref) {
        localStorage.setItem('ref', ref);
    }

    function _getAccessToken() {
        return localStorage.getItem('accessToken');
    }

    function _getRefreshToken() {
        return localStorage.getItem('refreshToken');
    }

    function _getUniversalLogin(universal) {
        return localStorage.getItem('universalLogin');
    }

    function _getMe() {
        const me = localStorage.getItem('me');
        return JSON.parse(me);
    }

    function _getRef() {
        return localStorage.getItem('ref');
    }

    function _getPrefix() {
        return localStorage.getItem('prefix');
    }

    function _clearToken() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('me');
        localStorage.removeItem('ref');
    }

    return {
        getService: _getService,
        setToken: _setToken,
        setPrefix: _setPrefix,
        setMe: _setMe,
        getMe: _getMe,
        setRef: _setRef,
        getRef: _getRef,
        getPrefix: _getPrefix,
        getAccessToken: _getAccessToken,
        getRefreshToken: _getRefreshToken,
        clearToken: _clearToken,
        setUniversalLogin: _setUniversalLogin,
        getUniversalLogin: _getUniversalLogin
    }
})();
export default LocalStorageService;
