import React, {createContext, useReducer} from "react";


const initialState = {
    active: "dashboard",
    menuCollpase: false,
}


function MenuActiveReducer(state = [], action) {
    switch (action.type) {
        case 'ACTIVE':
            return {
                ...state,
                active: action.payload,
            }
        case 'COLLAPSE':
            return {
                ...state,
                menuCollpase: action.payload,
            }
        default:
            return state;
    }
}

export const MenuActiveContext = createContext(initialState);


export const MenuActiveProvider = ({children}) => {

    const [state, dispatch] = useReducer(MenuActiveReducer, initialState);

    function menuActive(menu) {
        dispatch({
            type: 'Active',
            payload: menu
        })
    }

    function menuCollapse(mode) {
        // console.log(mode)
        dispatch({
            type: 'COLLAPSE',
            payload: mode
        })
    }


    return (
        <MenuActiveContext.Provider value={{
            state,
            menuCollpaseStatus: state.menuCollpase,
            menuActive,
            menuCollapse,
        }}>
            {children}
        </MenuActiveContext.Provider>
    );

};
