import LocalStorageService from "../services/LocalStorageService";

const localStorageService = LocalStorageService.getService();

export default function LoginReducer(state = [], action) {
    switch (action.type) {
        case 'SUBMIT':
            return {
                ...state,
                submitted: action.payload
            };
            break
        case 'LOGIN':
            localStorageService.setToken(action.payload);
            localStorageService.setPrefix(action.payload.prefix);
            localStorageService.setUniversalLogin(action?.universal ?? false);
            return {
                ...state,
                redirect: (typeof action.redir == `undefined` || action.redir == `` || action.redir == null) ? `/dashboard` : action.redir,
                loginFailStatus: false,
                isAuthenticated: true,
                accessToken: action.payload.accessToken,
                refeshToken: action.payload.refreshToken,
            };
        case 'LOGIN_FAILS':
            return {
                ...state,
                loginFailStatus: true,
                isAuthenticated: true,
                otpVerifyFailRequest: true,
                accessToken: null,
                refeshToken: null,
            };
        case 'LOGOUT' :
            return {
                ...state,
                isAuthenticated: false,
                accessToken: null,
                refeshToken: null,
            }
        case 'OTP_REQUEST' :
            const redir = "/register/otp?prefix=" + action.payload.prefix
            return {
                ...state,
                otpFailRequest: false,
                otpVerifyFailRequest: false,
                redirect: redir,
                otpRequest: action.payload,
                prefix: action.payload.prefix
            }
        case 'OTP_RE_REQUEST' :
            return {
                ...state,
                otpFailRequest: false,
                otpVerifyFailRequest: false,
                redirect: null,
                otpRequest: action.payload,
                prefix: action.payload.prefix
            }
        case 'OTP_REQUEST_KEEP_USERNAME' :
            // console.log(action.payload)
            return {
                ...state,
                username: action.payload.username,
                password: action.payload.password,
                promotion: action.payload.promotion,
            }
        case 'OTP_REQUEST_FAIL' :
            // console.log(action.payload)
            return {
                ...state,
                otpFailRequest: true,
                responseFail: action.payload
            }
        case 'OTP_VERIFY_FAIL' :
            return {
                ...state,
                otpVerifyFailRequest: true
            }
        case 'FAIL_VERIFY' :
            return {
                ...state,
                otpVerifyFailRequest: action.payload
            }
        case 'CLEAR_REDIR':
            return {
                ...state,
                redirect: null,
            }
        case 'SET_REF_PRO':
            return {
                ...state,
                refPro: action.payload,
            }
        default:
            return state;
    }
}
