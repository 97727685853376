import {Link} from "react-router-dom";
import {FaArrowLeft} from "react-icons/all";
import React from "react";
import {App} from "../../config/App";
import {FaLine} from "react-icons/fa";

const Contact = () => {
    return (
        <>
            <div className="heading-title">
                <div>
                    <h3>ติดต่อเรา</h3>
                    <div>ติดต่อสอบถามข้อมูลผ่าน LINE</div>
                </div>
                <Link to="/dashboard" className="back">
                    <FaArrowLeft/> ย้อนกลับ
                </Link>
            </div>


            {typeof App.lineQrcode !== `undefined` && App.lineQrcode !== `` && (
                <div className="my-5 text-center">
                    <img src={App.lineQrcode} className="w-50" alt=""/>
                    <div className="mt-3">
                        <a href={App.lineUrl} target="_blank" className="btn btn-success px-4 py-1 d-inline-flex align-items-center text-uppercase font-weight-bold">
                            <FaLine className="mr-2" style={{fontSize: "22px"}}/>
                            {App.lineId}
                        </a>
                    </div>
                </div>
            )}
        </>
    )
}

export default Contact
