import {Link, withRouter} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {FaArrowLeft, FaBroadcastTower, FaGamepad} from "react-icons/all";
import {Swiper, SwiperSlide} from 'swiper/react/swiper-react';
import 'swiper/swiper.min.css';
import {App} from "../../config/App";
import {NotificationManager} from "react-light-notifications";


const ProviderGaming = ({size, hideLink = false}) => {
    const [data, setData] = useState("sm")
    useEffect(() => {
        if (typeof size != `undefined`) {
            setData(size)
        }
    }, [])

    const handleLink = () => {
        if (hideLink) {
            NotificationManager.info({
                title: 'แจ้งเตือน',
                message: "กรุณาเข้าสู่ระบบ",
            });
        }
    }

    return (
        <>


            <div className="heading-title mb-3">
                <div>
                    <h3>คาสิโนสด</h3>
                    <div>LIVE CASINO</div>
                </div>
                {data == "lg" && (
                    <>
                        <Link to="/dashboard" className="back">
                            <FaArrowLeft/> ย้อนกลับ
                        </Link>
                    </>
                )}
            </div>

            <Swiper
                className={"mb-5"}
                spaceBetween={8}
                slidesPerView={'auto'}
            >

                <SwiperSlide>
                    <Link to={hideLink ? "" : "/sexy"} onClick={() => handleLink()}>
                        <img src={'/assets/images/games/sexygame.jpg?a=1'} alt="banner" className="w-100"/>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link to={hideLink ? "" : "/pretty"} onClick={() => handleLink()}>
                        <img src={'/assets/images/games/pretty.jpg?a=1'} alt="banner" className="w-100"/>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link to={hideLink ? "" : "/wm-gaming"} onClick={() => handleLink()}>
                        <img src={'/assets/images/games/wm.jpg?a=1'} alt="banner" className="w-100"/>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link to={hideLink ? "#" : "/sagame"} onClick={() => handleLink()}>
                        <img src={'/assets/images/games/sa.jpg?a=1'} alt="banner" className="w-100"/>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link to={hideLink ? "#" : "/dream"} onClick={() => handleLink()}>
                        <img src={'/assets/images/games/deam.jpg?a=1'} alt="banner" className="w-100"/>
                    </Link>
                </SwiperSlide>

            </Swiper>


            <div className="heading-title mb-3">
                <div>
                    <h3>สล็อตเกมส์</h3>
                    <div>SLOT CASINO GAMING</div>
                </div>
            </div>
            <Swiper
                spaceBetween={8}
                slidesPerView={'auto'}
            >
                <SwiperSlide>
                    <Link to={hideLink ? "#" : "/pgslot"} onClick={() => handleLink()}>
                        <img src={'/assets/images/games/pgslot.jpg?a=1'} alt="banner" className="w-100"/>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link to={hideLink ? "#" : "/slotxo"} onClick={() => handleLink()}>
                        <img src={'/assets/images/games/slotxo.jpg?a=1'} alt="banner" className="w-100"/>
                    </Link>
                </SwiperSlide>
                <SwiperSlide>
                    <Link to={hideLink ? "#" : "/live22"} onClick={() => handleLink()}>
                        <img src={'/assets/images/games/live22.jpg?a=1'} alt="banner" className="w-100"/>
                    </Link>
                </SwiperSlide>


            </Swiper>

            {App.gameSport && (
                <>
                    <div className="heading-title mt-5 mb-3">
                        <div>
                            <h3>กีฬาออนไลน์</h3>
                            <div>SPORT GAMING</div>
                        </div>
                    </div>
                    <Swiper
                        className={"mb-2"}
                        spaceBetween={8}
                        slidesPerView={'auto'}
                    >
                        <SwiperSlide>
                            <a href={App.lineUrl}>
                                <img src={'/assets/images/games/ufa.jpg?a=1'} alt="banner" className="w-100"/>
                            </a>
                        </SwiperSlide>
                        <SwiperSlide>
                            <a href={App.lineUrl}>
                                <img src={'/assets/images/games/lsm.jpg?a=1'} alt="banner" className="w-100"/>
                            </a>
                        </SwiperSlide>
                        <SwiperSlide>
                            <a href={App.lineUrl}>
                                <img src={'/assets/images/games/muaystep.jpg?a=1'} alt="banner" className="w-100"/>
                            </a>
                        </SwiperSlide>
                    </Swiper>
                    <div className="text-danger text-center mb-5">** หมายเหตุ เกมส์กีฬาสมัครผ่านไลน์ **</div>
                </>
            )}


            {/*<div className="row row-sm">*/}

            {/*    /!*<div className={(data == `lg` ? `col-lg-3` : `col-xl mb-xl-0`) + ` col-md-4 col-sm-6 col-6 mb-4`}>*!/*/}
            {/*    /!*    <div className="box">*!/*/}
            {/*    /!*        <div className="p-3">*!/*/}
            {/*    /!*            <div className="mb-2">*!/*/}
            {/*    /!*                <img src={'/assets/images/games/live22.jpg'} alt="banner" className="w-100"/></div>*!/*/}
            {/*    /!*            <div className="text-center mb-2"><i>LIVE 22</i></div>*!/*/}
            {/*    /!*            <div className="text-center">*!/*/}
            {/*    /!*                <Link to={"/live22"} className="btn btn-sm btn-primary px-4">เล่น</Link>*!/*/}
            {/*    /!*            </div>*!/*/}
            {/*    /!*        </div>*!/*/}
            {/*    /!*    </div>*!/*/}
            {/*    /!*</div>*!/*/}
            {/*    /!*<div className={(data == `lg` ? `col-lg-3` : `col-xl mb-xl-0`) + ` col-md-4 col-sm-6 col-6 mb-4`}>*!/*/}
            {/*    /!*    <div className="box">*!/*/}
            {/*    /!*        <div className="p-3">*!/*/}
            {/*    /!*            <div className="mb-2">*!/*/}
            {/*    /!*                <img src={'/assets/images/games/sa.jpg'} alt="banner" className="w-100"/></div>*!/*/}
            {/*    /!*            <div className="text-center mb-2"><i>SA Gaming</i></div>*!/*/}
            {/*    /!*            <div className="text-center">*!/*/}
            {/*    /!*                <Link to={"/sagame"} className="btn btn-sm btn-primary px-4">เล่น</Link>*!/*/}
            {/*    /!*            </div>*!/*/}
            {/*    /!*        </div>*!/*/}
            {/*    /!*    </div>*!/*/}
            {/*    /!*</div>*!/*/}


            {/*    /!*<div className={(data == `lg` ? `col-lg-3` : `col-xl mb-xl-0`) + ` col-md-4 col-sm-6 col-6 mb-4`}>*!/*/}
            {/*    /!*    <div className="box">*!/*/}
            {/*    /!*        <div className="p-3">*!/*/}
            {/*    /!*            <div className="mb-2">*!/*/}
            {/*    /!*                <img src={'/assets/images/games/deam.jpg'} alt="banner" className="w-100"/></div>*!/*/}
            {/*    /!*            <div className="text-center mb-2"><i>Dream Gaming</i></div>*!/*/}
            {/*    /!*            <div className="text-center">*!/*/}
            {/*    /!*                <Link to={"/dream"} className="btn btn-sm btn-primary px-4">เล่น</Link>*!/*/}
            {/*    /!*            </div>*!/*/}
            {/*    /!*        </div>*!/*/}
            {/*    /!*    </div>*!/*/}
            {/*    /!*</div>*!/*/}
            {/*    /!*<div className={(data == `lg` ? `col-lg-3` : `col-xl mb-xl-0`) + ` col-md-4 col-sm-6 col-6 mb-4`}>*!/*/}
            {/*    /!*    <div className="box">*!/*/}
            {/*    /!*        <div className="p-3">*!/*/}
            {/*    /!*            <div className="mb-2">*!/*/}
            {/*    /!*                <img src={'/assets/images/games/sexygame.jpg'} alt="banner" className="w-100"/></div>*!/*/}
            {/*    /!*            <div className="text-center mb-2"><i>Sexy Gaming</i></div>*!/*/}
            {/*    /!*            <div className="text-center">*!/*/}
            {/*    /!*                <Link to={"/sexy"} className="btn btn-sm btn-primary px-4">เล่น</Link>*!/*/}
            {/*    /!*            </div>*!/*/}
            {/*    /!*        </div>*!/*/}
            {/*    /!*    </div>*!/*/}
            {/*    /!*</div>*!/*/}


            {/*    <div className={(data == `lg` ? `col-lg-3` : `col-xl mb-xl-0`) + ` col-md-4 col-sm-6 col-6 mb-4`}>*/}
            {/*        <div className="box">*/}
            {/*            <div className="p-3">*/}
            {/*                <div className="mb-2">*/}
            {/*                    <img src="/assets/images/games/pgslot.jpg" alt="banner" className="w-100"/></div>*/}
            {/*                <div className="text-center mb-2"><i>PGSLOT</i></div>*/}
            {/*                <div className="text-center">*/}
            {/*                    <Link to={"/pgslot"} className="btn btn-sm btn-primary px-4">เล่น</Link>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className={(data == `lg` ? `col-lg-3` : `col-xl mb-xl-0`) + ` col-md-4 col-sm-6 col-6 mb-4`}>*/}
            {/*        <div className="box">*/}
            {/*            <div className="p-3">*/}
            {/*                <div className="mb-2">*/}
            {/*                    <img src={'/assets/images/games/slotxo.jpg'} alt="banner" className="w-100"/></div>*/}
            {/*                <div className="text-center mb-2"><i>SLOT XO</i></div>*/}
            {/*                <div className="text-center">*/}
            {/*                    <Link to={"/slotxo"} className="btn btn-sm btn-primary px-4">เล่น</Link>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    )
}
export default withRouter(ProviderGaming)
