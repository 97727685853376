import React, {useContext, useEffect, useState} from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";


import Sidebar from "../components/Sidebar";
import Deposit from "../components/Topup/Deposit";
import Withdraw from "../components/Topup/Withdraw";
import History from "../components/Topup/History";
import AffSummary from "../components/Affiliate/AffSummary";
import Profile from "../components/Account/Profile";
import Password from "../components/Account/Password";
import {AccountContext} from "../context/AccountContext";
import Header from "../components/Header/Header";
import LocalStorageService from "../services/LocalStorageService";
import Pgsoft from "../components/Games/Pgsoft";
import Slotxo from "../components/Games/Slotxo";
import Live22 from "../components/Games/Live22";
import Sexy from "../components/Games/Sexy";
import Dream from "../components/Games/Dream";
import Sagame from "../components/Games/Sagame";
import EditProfile from "../components/Account/EditProfile";
import Dashboard from "../components/Dashboard/Dashboard";
import BetHistory from "../components/Topup/BetHistory";
import Footer from "../components/Footer/Footer";
import Play from "../components/Games/Play";
import Error403 from "../components/Errors/Error403";
import Contact from "../components/Contact/Contact";
import Pretty from "../components/Games/Pretty";
import WM from "../components/Games/Wm";


const localStorageService = LocalStorageService.getService();

const games = [
    {name: "PGSLOT", path: 'pgslot', component: Pgsoft},
    {name: "SlotXO", path: 'slotxo', component: Slotxo},
    {name: "LIVE22", path: 'live22', component: Live22},
    {name: "SEXY Gaming", path: 'sexy', component: Sexy},
    {name: "DREAM Gaming", path: 'dream', component: Dream},
    {name: "SA Gaming", path: 'sagame', component: Sagame},
    {name: "Pretty Gaming", path: 'pretty', component: Pretty},
    {name: "WM Gaming", path: 'wm-gaming', component: WM},
]

const getRoutes = (
    <Switch>
        <Route exact path="/dashboard" component={Dashboard}/>
        <Route exact path="/deposit" component={Deposit}/>
        <Route exact path="/withdraw" component={Withdraw}/>
        <Route exact path="/history" component={History}/>
        <Route exact path="/bet-history" component={BetHistory}/>
        <Route exact path="/affiliate" component={AffSummary}/>
        {/*<Route exact path="/pgslot" component={Pgsoft}/>*/}
        {/*<Route exact path="/casino" component={Casino}/>*/}
        {/*<Route exact path="/sport" component={Casino}/>*/}
        {/*<Route exact path="/slot" component={Slot}/>*/}
        {/*<Route exact path="/games" component={Games}/>*/}
        {/*<Route exact path="/lotto" component={Casino}/>*/}
        {/*<Route exact path="/visual-sport" component={Casino}/>*/}
        <Route exact path="/games" component={Play}/>
        <Route exact path="/account" component={Profile}/>
        <Route exact path="/account/edit" component={EditProfile}/>
        <Route exact path="/password" component={Password}/>
        <Route exact path="/contact" component={Contact}/>
        {games.map(item => (
            <Route exact path={`/${item.path}`} component={item.component}/>
        ))}
    </Switch>
);

const SubMainLayout = () => {

    const location = useLocation()


    const {getProfile, profile} = useContext(AccountContext)
    useEffect(() => {
        document.body.classList.remove(`login`)
        getProfile()

        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        const prefix = params.get("prefix")


        if (localStorageService.getAccessToken() == "" || localStorageService.getAccessToken() == null) {
            if (prefix != "" && prefix != null) {
                window.location.href = "/login?prefix=" + prefix + "&redir=" + location.pathname
            } else {
                if (localStorageService.getUniversalLogin() == 1) {
                    window.location.href = "/ulogin"
                }
            }
        }

    }, [])


    if (localStorageService.getAccessToken() == "" || localStorageService.getAccessToken() == null) {
        // alert(localStorageService.getUniversalLogin())
        if (localStorageService.getUniversalLogin() == 1) {
            return <Redirect to={"/ulogin"}/>
        } else {
            if (localStorageService.getPrefix() != null) {
                // console.log("/login?prefix=" + localStorageService.getPrefix() + "&redir=" + location.pathname)
                return <Redirect to={"/login?prefix=" + localStorageService.getPrefix()}/>
            } else
                return <Error403/>
        }
    }

    return (
        <>
            <Header profile={profile}/>
            <div id="main-body" className="container">
                <div className="main">
                    <Sidebar games={games} profile={profile}/>
                    <div className="content-page">
                        <div className="content-page-inner">
                            {getRoutes}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </>

    );
}

export default SubMainLayout;
