import {useEffect, useState} from "react";


const Popup = ({profile}) => {
    const [hasBank, setHasBank] = useState(true)
    useEffect(() => {
        if (profile && typeof profile.id != "undefined") {
            // console.log(profile)
            if (typeof profile.bankNumber == 'undefined' || profile.bankNumber == "") {
                setHasBank(false)
            }
        }
    }, [profile])

    return (
        <>
            <div className={"open-game-popup " + (!hasBank ? "" : "d-none")}>
                <div className="popup-alert">
                    <div className="content">
                        <div className="h4 text-center"><i>แจ้งเตือน</i></div>
                        <p className="text-center">กรุณาใส่บัญชีธนาคารของลูกค้าให้เรียบร้อย<br/>ก่อนทำรายการฝากและถอน</p>
                        <div className="text-center">
                            <a href="/account" className="btn btn-primary">คลิก ไปหน้าแก้ไขข้อมูล</a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


export default Popup
