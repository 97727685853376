export default function HistoryReducer(state = [], action) {
    switch (action.type) {
        case 'FETCH':
            return {
                ...state,
                data: action.payload.data,
                page: action.payload.page,
                pageSize: action.payload.pageSize,
                total: action.payload.total,
            }
        case 'FETCH_TOPUP':
            console.log(action.payload.data)
            return {
                ...state,
                data: action.payload.data,
                page: action.payload.page,
                pageSize: action.payload.pageSize,
                total: action.payload.total,
            }
        case 'NO_DATA':
            return {
                ...state,
                data: [],
                page: 1,
                pageSize: 0,
                total: 0,
            }
    }
}
