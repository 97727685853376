import LocalStorageService from "../../services/LocalStorageService";
import React, {useContext, useEffect, useState} from "react";
import {LoginContext} from "../../context/LoginContext";
import {App} from "../../config/App";
import {Link} from "react-router-dom";
import ProviderGaming from "../Games/ProviderGaming";
import {FaLine} from "react-icons/fa";

const localStorageService = LocalStorageService.getService();

const ULogin = () => {
    const {loginState, login, clearRedirect, setSubmit} = useContext(LoginContext)
    const [prefix, setPrefix] = useState("")
    const [ref, setRef] = useState("")
    const [redir, setRedir] = useState("")
    const [data, setData] = useState({
        'username': null,
        'password': null,
    })


    useEffect(() => {
        document.body.classList.add(`login`)
        setSubmit(false)

        if (localStorageService.getAccessToken() != `` && localStorageService.getAccessToken() != null) {
            window.location.href = `/dashboard`
        }


    }, [])

    const handleInput = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const handleLoginAction = (e) => {
        e.preventDefault();
        setSubmit(true)

        const mPrefix = data.username.slice(0, 4)
        const username = data.username.substring(4)

        login(username, data.password, mPrefix?.toUpperCase(), redir, 1)
    }

    if (loginState.redirect) {
        window.location.href = loginState.redirect
        return (<></>)
    }

    return (
        <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-6 col-md-8">
                <div className="container-fluid my-5">
                    <div className="mb-4 text-center">
                        <div className="mb-2">
                            <img src={App.logo} alt="{App.app_name}" className="w-75"/>
                        </div>
                    </div>

                    <div className="h6 mb-1">เข้าสู่ระบบ</div>
                    <small className="text-muted mb-3 d-block">LIVE CASINO & SLOT BETTING</small>
                    <form onSubmit={e => handleLoginAction(e)}>
                        <div className="mb-4">
                            <div className="form-group">
                                <div className="input-group mb-3">
                                    {prefix && (
                                        <div className="input-group-prepend">
                                            <span className="input-group-text font-weight-bold">{prefix}</span>
                                        </div>
                                    )}
                                    <input type="text" name="username" autoComplete="new-password" placeholder="บัญชีผู้ใช้งาน" onChange={e => handleInput(e)} className="form-control"/>
                                </div>

                            </div>
                            <div className="form-group">
                                <input type="password" name="password" autoComplete="new-password" placeholder="รหัสผ่าน" onChange={e => handleInput(e)} className="form-control"/>
                            </div>
                            {loginState.submitted ? (
                                <button disabled={true} className="btn btn-warning btn-block">กำลังเข้าสู่ระบบ...</button>) : (
                                <button onClick={e => handleLoginAction(e)} className="btn btn-warning text-bold  btn-block">เข้าสู่ระบบ</button>)}
                        </div>
                    </form>

                    <ProviderGaming hideLink={true}/>


                    {typeof App.lineQrcode !== `undefined` && App.lineQrcode !== `` && (
                        <div className="my-5 text-center">
                            <img src={App.lineQrcode} className="w-50" alt=""/>
                            <div className="mt-3">
                                <a href={App.lineUrl} target="_blank" className="btn btn-success px-4 py-1 d-inline-flex align-items-center text-uppercase font-weight-bold">
                                    <FaLine className="mr-2" style={{fontSize: "22px"}}/>
                                    {App.lineId}
                                </a>
                            </div>
                        </div>
                    )}


                    <div className="text-center">
                        <h1 className="h3 text-uppercase font-weight-bold mb-0 text-warning">{App.app_name}</h1>
                        <div className="text-white font-weight-bold ">LIVE CASINO & SLOT BETTING</div>
                        <small>
                            Copyright © 2021 <span className="text-warning text-uppercase">{App.app_name} </span> All rights reserved.
                        </small>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ULogin
