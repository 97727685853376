import React, {useContext, useEffect} from "react";
import {Link} from "react-router-dom";
import {AccountContext} from "../../context/AccountContext";
import CurrencyFormat from 'react-currency-format';
import ProviderGaming from "../Games/ProviderGaming";
import {
    FaArrowDown,
    FaArrowUp,
    FaChartLine, FaDollarSign,
    FaHistory,
    FaLockOpen, FaPhone,
    FaPowerOff, FaRegUser,
    FaRegUserCircle,
    FaUserAlt, IoReload
} from "react-icons/all";
import moment from "moment";


const Dashboard = () => {

    const {profile, getProfile, reloadProfile, setMenuActive} = useContext(AccountContext)

    const handleProfile = (event) => {
        event.preventDefault()
        getProfile()
    }

    useEffect(() => {
        setMenuActive('dashboard')
    }, [])

    return (
        <>
            <div className="heading-title">
                <div>
                    <h3>บัญชีของฉัน</h3>
                    <div><FaRegUser/> {profile && (
                        <>
                            {profile.prefix}{profile.username}
                        </>
                    )}</div>
                </div>
            </div>
            <div className="circle-wrapper mb-3">
                <div className="circle-amount">
                    <div className="circle-amount-inner">
                        <div className="content" onClick={e => handleProfile(e)}>
                            <div className="title">ยอดเงินที่ใช้ได้</div>
                            <div className="unit mb-1">
                                <CurrencyFormat value={ reloadProfile ? 0 : ( profile && profile.balance ? profile.balance : 0) } displayType={'text'} suffix="" thousandSeparator={true}/>
                            </div>
                            <div className={"mb-0 " + (reloadProfile ? "icon-spin" : "")} style={{fontSize: "12px"}}><IoReload/> Refresh</div>
                            {/*<button onClick={handleProfile} className="btn btn-success py-1 px-2 btn-sm">ตรวจสอบยอดเงิน</button>*/}
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-center text-muted mb-4">
                <a href="#" onClick={e => handleProfile(e)} className="text-muted">ข้อมูล ณ วันที่ {moment(profile.updateTime).format("YYYY-MM-DD HH:mm:ss")}</a>
            </div>

            <div className="row row-sm mb-4 button-icon">
                <div className="col-xl col-lg-2 col-md-3 col-sm-4 col-6 mb-4">
                    <div className="item-button text-center">
                        <Link to="/deposit">
                            <FaArrowDown/>
                            <div>ฝากเงิน</div>
                        </Link>
                    </div>
                </div>
                <div className="col-xl col-lg-2 col-md-3 col-sm-4 col-6 mb-4">
                    <div className="item-button text-center">
                        <Link to="/withdraw">
                            <FaArrowUp/>
                            <div>ถอนเงิน</div>
                        </Link>
                    </div>
                </div>
                <div className="col-xl col-lg-2 col-md-3 col-sm-4 col-6 mb-4">
                    <div className="item-button text-center">
                        <Link to="/affiliate">
                            <FaDollarSign/>
                            <div>ลิ้งรับทรัพท์</div>
                        </Link>
                    </div>
                </div>
                <div className="col-xl col-lg-2 col-md-3 col-sm-6 col-6 mb-4">
                    <div className="item-button text-center">
                        <Link to="/history">
                            <FaHistory/>
                            <div>ประวัติทำรายการ</div>
                        </Link>
                    </div>
                </div>
                <div className="col-xl col-lg-2 col-md-12 col-sm-6 col-12 mb-4">
                    <div className="item-button text-center">
                        <Link to="/bet-history">
                            <FaChartLine/>
                            <div>ประวัติวางเดิมพัน</div>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="row row-sm mb-4 button-icon">

                <div className="col-xl col-lg-2 col-md-3 col-sm-4 col-6 mb-4">
                    <div className="item-button text-center">
                        <Link to="/account">
                            <FaRegUserCircle/>
                            <div>บัญชีของฉัน</div>
                        </Link>
                    </div>
                </div>
                <div className="col-xl col-lg-2 col-md-3 col-sm-4 col-6 mb-4">
                    <div className="item-button text-center">
                        <Link to="/password">
                            <FaLockOpen/>
                            <div>เปลี่ยนรหัสผ่าน</div>
                        </Link>
                    </div>
                </div>
                <div className="col-xl col-lg-2 col-md-3 col-sm-4 col-6 mb-4">
                    <div className="item-button text-center">
                        <Link to="/contact">
                            <FaPhone/>
                            <div>ติดต่อเรา</div>
                        </Link>
                    </div>
                </div>
                <div className="col-xl col-lg-2 col-md-3  col mb-4">
                    <div className="item-button text-center">
                        <Link to="/logout" className="text-danger">
                            <FaPowerOff/>
                            <div>ออกจากระบบ</div>
                        </Link>
                    </div>
                </div>
            </div>

            {/*<Breadcrumb>*/}
            {/*    <Breadcrumb.Item href="#">หน้าแรก</Breadcrumb.Item>*/}
            {/*    <Breadcrumb.Item active>แดชบอร์ด</Breadcrumb.Item>*/}
            {/*</Breadcrumb>*/}
            {/*<div className="row mb-4 mb-xl-0 d-none">*/}
            {/*    <div className="col-lg-12">*/}
            {/*        <div className="box mb-4 w-100">*/}
            {/*            <div className="row justify-content-center">*/}
            {/*                <div className="col-lg-7">*/}

            {/*                    <div className="p-4 pb-lg-2">*/}
            {/*                        <div className="heading line-o h4 mb-4">*/}
            {/*                            <i>ยินดีตอนรับ <span className="h5 text-muted pl-3">{profile && profile.username}</span></i>*/}
            {/*                        </div>*/}

            {/*                        <div className="h3 mb-4">*/}
            {/*                            <div className="d-flex align-items-center justify-content-end">*/}
            {/*                                <div className="underline-red pb-2">*/}
            {/*                                    <span className="h5 mb-0 text-muted pr-3">ยอดเครดิต</span>*/}
            {/*                                    <span>*/}
            {/*                                <i><CurrencyFormat value={profile && profile.balance ? profile.balance : 0} displayType={'text'} suffix=" บาท" thousandSeparator={true}/></i>*/}
            {/*                            </span>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}

            {/*                        <div className="row mb-4">*/}
            {/*                            <div className="col-lg-6 mb-4 mb-xl-0">*/}
            {/*                                <Link to="/deposit" className="btn-primary btn btn-block btn-lg"><i>แจ้งเติมเงิน</i></Link>*/}
            {/*                            </div>*/}
            {/*                            <div className="col-lg-6">*/}
            {/*                                <Link to="/withdraw" className="btn-outline-secondary btn btn-block btn-lg"><i>แจ้งถอนเงิน</i></Link>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}

            {/*                        <Link to="/affliate" className="btn-secondary btn btn-block btn-lg"><i>ลิ้งรับทรัพท์</i></Link>*/}
            {/*                        <hr className="sm"/>*/}
            {/*                        <div className="d-flex align-items-center justify-content-end font-weight-light">*/}
            {/*                            <Link to="/password" className="text-muted mr-2"><i>เปลี่ยนรหัสผ่าน</i></Link>*/}
            {/*                            <div className="devide mr-2">/</div>*/}
            {/*                            <Link to="/account" className="text-danger"><i>จัดการบัญชี</i></Link>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}

            <ProviderGaming/>
        </>
    );
}

export default Dashboard
