import {Link, withRouter} from "react-router-dom";
import {App} from "../../config/App";
import React, {useContext, useEffect, useRef, useState} from "react";
import {LoginContext} from "../../context/LoginContext";
import {useToasts} from "react-toast-notifications";
import {NotificationManager} from "react-light-notifications";
import LocalStorageService from "../../services/LocalStorageService";

const Verify = () => {
    const localStorageService = LocalStorageService.getService();
    const {loginState, otpVerify, clearRedirect, setSubmit, reOtp} = useContext(LoginContext)
    const [otpCode, setOtpCode] = useState("")
    const [prefix, setPrefix] = useState("")
    const [ref, setRef] = useState(localStorageService.getRef())
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [redir, setRedir] = useState(0)

    const [count, setCount] = useState(60)

    useEffect(() => {
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        const mPrefix = params.get("prefix")

        if (mPrefix == null || mPrefix == "") {
            if (App.prefix != ``) {
                window.location.href = `/register?prefix=${App.prefix}`
                return
            } else {
                window.location.href = "/register";
                return
            }
        } else if (loginState.username == null || loginState.username == "") {
        }

        if (localStorageService.getAccessToken() != `` && localStorageService.getAccessToken() != null) {
            window.location.href = `/dashboard`
        }

        setPrefix(mPrefix)
    }, []);

    useEffect(() => {
        if (count === 0) {
            setSubmit(true)
            window.location.href = `/register?prefix=${App.prefix}` + (ref ? `&ref=${ref}` : '')
            return
        }
        if (!count) return;
        const intervalId = setInterval(() => {
            let left = count - 1
            setCount(left)
        }, 1000);

        return () => clearInterval(intervalId);

    }, [count])

    useEffect(() => {
        setSubmit(false)
    }, [])

    useEffect(() => {
        clearRedirect()
        setRedir(1)
    }, [])

    useEffect(() => {
        if (loginState.username != null) {
            setUsername(loginState.username)
        }
    }, [loginState.username])

    useEffect(() => {
        if (loginState.password != null) {
            setPassword(loginState.password)
        }
    }, [loginState.password])

    useEffect(() => {
        if (loginState.otpVerifyFailRequest) {
            NotificationManager.error({
                title: 'ผิดพลาด',
                message: 'การยืนยันไม่ถูกต้อง',
            });
        }
    }, [loginState.otpVerifyFailRequest])


    const handleVerify = () => {
        if (username == "" || password == "") {
            // alert("เข้าใช้งานไม่ถูกต้อง")
            NotificationManager.error({
                title: 'ผิดพลาด',
                message: "เข้าใช้งานไม่ถูกต้อง",
            });
            window.location.href = `/register?prefix=${prefix}`;
            return
        }

        if (typeof prefix == "undefined" || prefix == "" || prefix == null) {
            NotificationManager.error({
                title: 'ผิดพลาด',
                message: "การเข้าใช้งาน Prefix ไม่ถูกต้อง",
            });
            return
        }

        setSubmit(true)
        otpVerify(otpCode, prefix)
    }

    const handleOtpRequest = (e) => {
        e.preventDefault()
        setSubmit(true)
        reOtp()
    }

    return (
        <>
            <div className="row justify-content-center">
                <div className="col-xl-3 col-lg-6 col-md-8">
                    <div className="container-fluid my-5">
                        <div className="mb-4 text-center">
                            <div className="mb-2">
                                <img src={App.logo} alt="{App.app_name}" className="w-75"/>
                            </div>

                        </div>
                        <div className="h6 mb-1">ยืนยันรหัส OTP จาก {loginState.otpRequest && loginState.otpRequest.phone}</div>
                        <small className="text-muted mb-3 d-block">อ้างอิง: {loginState.otpRequest && loginState.otpRequest.code} และ OTP จะหมดอายุในอีก {count} วินาที</small>

                        <div className="mb-4">
                            <div className="form-group">
                                <input type="text" autoComplete="new-password" onChange={e => setOtpCode(e.target.value)} placeholder="รหัส OTP" className="form-control"/>
                            </div>

                            {/*{!loginState.submitted && (*/}
                            {/*    <div className="text-center mb-3">*/}
                            {/*        <Link onClick={e => handleOtpRequest(e)} className="text-white mb-3">ขอ OTP ใหม่อีกครั้ง</Link>*/}
                            {/*    </div>*/}
                            {/*)}*/}

                            <div className="mb-4">
                                {loginState.prefix == null ? (
                                    <>
                                        <button type="button" disabled={true} className="btn btn-warning text-bold  btn-block mb-2">การเข้าใช้งานไม่ถูกต้อง</button>
                                        <a href={`/register?prefix=${prefix}` + (ref ? `&ref=${ref}` : '')} className="btn btn-dark text-bold btn-block">ย้อนกลับ</a>
                                    </>
                                ) : (
                                    <>
                                        {loginState.submitted ? (
                                            <button disabled={true} className="btn btn-warning text-bold  btn-block">รอสักครู่...</button>) : (
                                            <>
                                                {/*<button type="button" onClick={e => handleOtpRequest(e)} className="btn btn-warning  btn-block mb-3">ขอ OTP ใหม่อีกครั้ง</button>*/}
                                                <div className="">
                                                    <button type="button" onClick={handleVerify} className="btn btn-warning text-bold  btn-block mb-2">ยืนยัน</button>
                                                    {ref && ref != null ?
                                                        <a href={`/register?prefix=${prefix}&ref=${ref}`} className="btn btn-dark text-bold btn-block">ย้อนกลับ</a> :
                                                        <a href={`/register?prefix=${prefix}`} className="btn btn-dark text-bold  btn-block">ย้อนกลับ</a>}

                                                </div>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>

                            <div className="text-center">
                                <h1 className="h3 text-uppercase font-weight-bold mb-0 text-warning">{App.app_name}</h1>
                                <div className="text-white font-weight-bold ">LIVE CASINO & SLOT BETTING</div>
                                <small>
                                    Copyright © 2021 <span className="text-warning text-uppercase">{App.app_name} </span> All rights reserved.
                                </small>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}


export default withRouter(Verify)
