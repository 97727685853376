import React, {createContext, useReducer} from "react";
import LoginReducer from "../reducers/LoginReducer";
import UserService from "../services/UserService";
import {NotificationManager} from "react-light-notifications";
import LocalStorageService from "../services/LocalStorageService";


const initialState = {
    username: null,
    password: null,
    prefix: null,
    redirect: null,
    otpRequest: {},
    otpFailRequest: false,
    reOtpRerquest: false,
    otpVerifyFailRequest: false,
    loginFailStatus: false,
    isAuthenticated: false,
    accessToken: null,
    refeshToken: null,
    submitted: false,
    responseFail: null,
    refPro: null,
    promotion: null,

}


export const LoginContext = createContext(initialState);
export const LoginProvider = ({children}) => {

    const [state, dispatch] = useReducer(LoginReducer, initialState);


    function login(username, password, prefix, redir, universal = 0) {
        UserService.signin(username, password, prefix).then(resp => {
            dispatch({
                type: 'LOGIN',
                payload: resp.data,
                redir: redir,
                universal: universal
            })
            setSubmit(true)
        }).catch(e => {
            NotificationManager.error({
                title: 'เกิดข้อผิดพลาด',
                message: 'ชื่อผู้ใช้งานหรือรหัสผ่านไม่ถูกต้อง',
            });
            dispatch({
                type: 'LOGIN_FAILS'
            })
            // console.log("error")
            setSubmit(false)
        })
    }

    function otp(phone, prefix, username, password, promotion) {
        UserService.otp(phone, prefix, username, promotion, password).then(resp => {
            dispatch({
                type: 'OTP_REQUEST',
                payload: resp.data
            })
            dispatch({
                type: 'OTP_REQUEST_KEEP_USERNAME',
                payload: {
                    username: username,
                    password: password,
                    promotion: promotion
                }
            })


            // dispatch({
            //     type: 'LOGIN',
            //     payload: resp.data
            // })
            // NotificationManager.success({
            //     title: 'สำเร็จ',
            //     message: "สมัครสมาชิกสำเร็จ",
            // });
            setSubmit(false)
            setFailVerify(false)
            //window.location.href = "/dashboard"

        }).catch((e) => {
            const errors = e.error
            if (e.error.response.status == 403) {
                dispatch({
                    type: 'OTP_REQUEST_FAIL',
                    payload: {
                        status: 0,
                        message: 'คุณสามารถขอ OTP ใหม่ได้ภายใน 1 นาที',
                    }
                })
                setSubmit(false)
                return;
            }
            if (typeof errors != 'undefined') {
                // dispatch({
                //     type: 'OTP_REQUEST_FAIL',
                //     payload: {
                //         status: e.error.response.status,
                //         message: ``,
                //     }
                // })
                const msg = e.error.response.data
                switch (msg) {
                    case `REF_INVALID` :
                        setSubmit(false)
                        NotificationManager.error({
                            title: 'ผิดพลาด',
                            message: `รหัสผู้แนะนำ (ref) ไม่ถูกต้อง`,
                        });
                        return;
                    case `PHONE_EXIST` :
                        setSubmit(false)
                        NotificationManager.error({
                            title: 'ผิดพลาด',
                            message: `${phone} ถูกใช้งานแล้ว`,
                        });
                        return;
                    case `PREFIX_INVALID` :
                        setSubmit(false)
                        NotificationManager.error({
                            title: 'ผิดพลาด',
                            message: `PREFIX ไม่ถูกต้อง`,
                        });
                        return;
                }

            } else {
                dispatch({
                    type: 'OTP_REQUEST_FAIL',
                    payload: {
                        status: 0,
                        message: "Service Unavailable",
                    }
                })
            }
            NotificationManager.error({
                title: 'ผิดพลาด',
                message: "กรุณาลองใหม่อีกครั้ง",
            });
            setSubmit(false)
        })
    }

    function otpRef(prefix, ref) {
        UserService.getRefPro(prefix, ref).then(resp => {
            dispatch({
                type: 'SET_REF_PRO',
                payload: resp.data
            })
        }).catch(e => {
            dispatch({
                type: 'SET_REF_PRO',
                payload: null
            })
        })
    }

    function reOtp() {
        UserService.otp(state.otpRequest.phone, state.prefix, state.username).then(resp => {
            dispatch({
                type: 'OTP_RE_REQUEST',
                payload: resp.data
            })
            setSubmit(false)
        }).catch((e) => {
            const errors = e.error

            if (e.error.response.status == 403) {
                NotificationManager.error({
                    title: 'ไม่สำเร็จ',
                    message: 'คุณสามารถขอ OTP ใหม่ได้ภายใน 1 นาที',
                });
                setSubmit(false)
                return;
            }

            if (typeof errors != 'undefined') {
                dispatch({
                    type: 'OTP_REQUEST_FAIL',
                    payload: {
                        status: e.error.response.status,
                        message: e.error.response.data,
                    }
                })
                NotificationManager.error({
                    title: 'ไม่สำเร็จ',
                    message: e.error.response.data,
                });
            } else {
                dispatch({
                    type: 'OTP_REQUEST_FAIL',
                    payload: {
                        status: 0,
                        message: "Service Unavailable",
                    }
                })
                NotificationManager.error({
                    title: 'ไม่สำเร็จ',
                    message: "Service Unavailable",
                });
            }
            setSubmit(false)
        })
    }

    function otpVerify(otp, prefix) {
        setFailVerify(false)

        if (typeof prefix == "undefined" || prefix == "" || prefix == null) {
            NotificationManager.error({
                title: 'ไม่สำเร็จ',
                message: "กรุณาลองใหม่อีกครั้ง",
            });
            return;
        }
        console.log(state)
        UserService.otpVerify(state.otpRequest.phone, state.otpRequest.code, otp, prefix, state.username, state.password, state.promotion).then(resp => {
            // console.log(resp.data)
            dispatch({
                type: 'LOGIN',
                payload: resp.data
            })
            const localStorageService = LocalStorageService.getService();
            localStorageService.setRef(``)
            NotificationManager.success({
                title: 'สำเร็จ',
                message: "ยืนยันหมายเลขโทรศัพท์สำเร็จ",
            });

            // setFailVerify(false)
            window.location.href = "/dashboard"
        }).catch(e => {
            // console.log('error')
            NotificationManager.error({
                title: 'ผิดพลาด',
                message: "ยินยัน OTP ไม่สำเร็จ",
            });
            // setFailVerify(true)
        }).finally(() => {
            setSubmit(false)
        })
    }

    function logout() {
        dispatch({
            type: 'LOGOUT'
        })
    }

    function clearRedirect() {
        dispatch({
            type: 'CLEAR_REDIR'
        })
    }

    function setSubmit(status) {
        dispatch({
            type: 'SUBMIT',
            payload: status
        })
    }

    function setFailVerify(status) {
        dispatch({
            type: 'FAIL_VERIFY',
            payload: status
        })
    }

    return (
        <LoginContext.Provider value={{
            loginState: state,
            login,
            logout,
            otp,
            otpVerify,
            clearRedirect,
            setSubmit,
            reOtp,
            otpRef
        }}>
            {children}
        </LoginContext.Provider>
    );

};
