import React, {useContext, useEffect, useState} from "react";
import {Link, useHistory, useLocation} from "react-router-dom";
import {
    AiFillDollarCircle,
    AiOutlineDollarCircle,
    BiFootball,
    CgSmartHomeWashMachine,
    FaHistory,
    FaHome,
    HiCubeTransparent,
    ImGift,
    ImSpades,
    IoFootballOutline,
    IoGameControllerOutline,
    RiAccountCircleLine,
    RiLockPasswordLine,
    RiLogoutBoxRLine, RiPhoneLine
} from "react-icons/all";
import {MenuActiveContext} from "../../context/MenuActiveContext";


const Sidebar = ({games}) => {
    const location = useLocation()
    const history = useHistory()
    const [active, setActive] = useState('dashboard')
    const {menuActive, menuCollpaseStatus, menuCollapse} = useContext(MenuActiveContext)

    useEffect(() => {
        const menu = (location.pathname).replace('/', '')
        handleActive(menu)
    }, []);

    // useEffect(() => {
    //     console.log(menuCollpaseStatus)
    // }, [menuCollpaseStatus])


    useEffect(() => {
        return history.listen((location) => {
            // console.log(`You changed the page to: ${location.pathname}`)
            const menu = (location.pathname).replace('/', '')
            handleActive(menu)

        })
    }, [history])

    const handleActive = (menu) => {
        setActive(menu)
        menuActive(menu)
        menuCollapse(false)
    }

    const handleCloseMenu = () => {
        menuCollapse(false)
    }

    return (
        <>
            <div className={"sidebar " + (menuCollpaseStatus ? 'sidebar-show' : '')} id="navbarSupportedContent">
                <ul>
                    <div className="close" onClick={handleCloseMenu}>x</div>
                    <li className={active === "dashboard" ? "active" : ""}>
                        <Link to="/dashboard">
                            <FaHome/>
                            <span>หน้าแรก</span>
                        </Link>
                    </li>
                    <li className={active === "deposit" ? "active" : ""}><Link to="/deposit"><AiFillDollarCircle/>
                        <span>ฝากเงิน</span></Link></li>
                    <li className={active === "withdraw" ? "active" : ""}><Link to="/withdraw"><AiOutlineDollarCircle/>
                        <span>ถอนเงิน</span></Link></li>
                    <li className={active === "history" ? "active" : ""}><Link to="/history"><FaHistory/>
                        <span>ประวัติฝากถอน</span></Link></li>
                    <li className={active === "bet-history" ? "active" : ""}><Link to="/bet-history"><FaHistory/>
                        <span>ประวัติการวางเดิมพัน</span></Link></li>
                    {/*<li className={active === "affiliate" ? "active" : ""}><Link to="/affiliate"><ImGift/>*/}
                    {/*    <span>ลิ้งรับทรัพย์</span></Link></li>*/}
                    {games.map((item, index) => (
                        <li key={index + item.path} className={active === item.path ? "active" : ""}>
                            <Link to={`/${item.path}`}>
                                <img src={`/assets/images/icons/${item.path}.png`} width={20} alt=""/>
                                <span>{item.name}</span></Link></li>
                    ))}
                    <li className={active === "account" ? "active" : ""}><Link to="/account"><RiAccountCircleLine/>
                        <span>บัญชี</span></Link></li>
                    <li className={active === "password" ? "active" : ""}><Link to="/password"><RiLockPasswordLine/>
                        <span>เปลี่ยนรหัสผ่าน</span></Link></li>
                    <li className={active === "contact" ? "active" : ""}><Link to="/contact"><RiPhoneLine/>
                        <span>ติดต่อเรา</span></Link></li>
                    <li className={active === "logout" ? "active" : ""}><Link to="/logout"><RiLogoutBoxRLine/>
                        <span>ออกจากระบบ</span></Link></li>
                </ul>
            </div>
        </>
    );
}

export default Sidebar
