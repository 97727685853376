import {useContext, useEffect, useState} from "react";
import {Switch} from "@mui/material";
import {AccountContext} from "../../context/AccountContext";
import {Bank} from "../../config/Bank";

const TogglePromotion = () => {
    const [checked, setChecked] = useState(false)
    const [user, setUser] = useState(false)
    const {setTogglePromotion, profile} = useContext(AccountContext)

    useEffect(() => {
        if (typeof profile !== `undefined` && profile !== `` && profile !== null) {
            setUser(profile)
            setChecked(profile?.promotion ?? false)
        }
    }, [profile])

    const handleChange = (e) => {
        // let c = false
        // if (e.target.value === 'on') {
        //     c = true
        // }
        // console.log(e.target.checked)
        let confirm = window.confirm("ยืนยันการเปลี่ยนแปลง?")
        if (confirm) {
            setChecked(e.target.checked)
            setTogglePromotion()
        }
    }
    return (
        <div className="box nor position-relative mb-4">
            <div className="p-4">
                <div className="row align-items-center">
                    <div className="col-6 ">
                        ตั้งค่าการรรับโปรโมชั่น
                    </div>
                    <div className="col-6 text-sm-right d-flex justify-content-end">
                        <Switch
                            checked={checked}
                            onChange={handleChange}
                            inputProps={{'aria-label': 'controlled'}}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TogglePromotion
