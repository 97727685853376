import {Route, Switch} from "react-router-dom";
import ProviderContext from "./context/ProviderContext";
import MainLayout from "./layouts/MainLayout";

function App() {



    return (
        <>
            <ProviderContext>
                <Switch>
                    <Route path="/" component={MainLayout}/>
                </Switch>
            </ProviderContext>
        </>
    );
}

export default App;
