import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {AiFillDollarCircle, FaArrowLeft, FaHistory} from "react-icons/all";
import kbank from "../../assets/images/banks/kbank.png"
import banner_golden1 from "../../assets/images/banners/banner_golden1.png"
import banner_golden2 from "../../assets/images/banners/banner_golden2.png"
import banner_joker from "../../assets/images/banners/banner_joker.png"
import banner_live from "../../assets/images/banners/banner_live.png"
import {Breadcrumb} from "react-bootstrap";
import ProviderGaming from "../Games/ProviderGaming";
import {AccountContext} from "../../context/AccountContext";
import Popup from "./Popup";
import {NotificationManager} from "react-light-notifications";


const Deposit = () => {

    const {getAgentBank, agentBank, profile} = useContext(AccountContext)


    useEffect(() => {
        getAgentBank();
    }, [])


    const addDefaultSrc = (ev) => {
        ev.target.src = '/assets/images/games/default.png'
    }


    const getBankIcon = (code) => {
        code = code.toLowerCase()
        return `/assets/images/banks/${code}.png`
    }

    const copyToClipboard = (str) => {
        navigator.clipboard.writeText(str)
        NotificationManager.success({
            title: 'แจ้งเตือน',
            message: "คัดลอกหมายเลขบัญชีแล้ว",
        });
    }

    return (
        <>
            <div className="heading-title">
                <div>
                    <h3>ฝากเงิน</h3>
                    <div>จำนวนเงินที่ต้องการฝาก ขั้นต่ำ 100 บาท</div>
                </div>
                <Link to="/dashboard" className="back">
                    <FaArrowLeft/> ย้อนกลับ
                </Link>
            </div>

            <Popup profile={profile}/>

            {/*<div className="row no-gutters">*/}
            {/*    <div className="col-lg-2">*/}
            {/*        <Link to="/deposit" className="btn btn-primary btn-block nor py-3" style={{minHeight: "58px"}}>*/}
            {/*            <AiFillDollarCircle/> <span><i>แจ้งเติมเครดิต</i></span>*/}
            {/*        </Link>*/}
            {/*    </div>*/}
            {/*    <div className="col-lg-2">*/}
            {/*        <Link to="/withdraw" className="btn btn-outline-secondary nor btn-block py-3">*/}
            {/*            <AiFillDollarCircle/> <span><i>แจ้งถอนเงิน</i></span>*/}
            {/*        </Link>*/}
            {/*    </div>*/}
            {/*    <div className="col-lg-2">*/}
            {/*        <Link to="/history" className="btn btn-outline-secondary nor btn-block py-3" style={{marginLeft: "-1px"}}>*/}
            {/*            <FaHistory/> <span><i>ประวัติฝากถอน</i></span>*/}
            {/*        </Link>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="row">
                <div className="col-lg-12">
                    <div className="nor position-relative mb-4">
                        <div className="p-4">
                            <div className="text-center mb-3">
                                <h4>โอนมายังบัญชี</h4>
                                <div className={"bank-icon-logo m-auto " + (agentBank && agentBank.bank && ("has-logo"))}>
                                    {agentBank && agentBank.bank && (
                                        <img src={getBankIcon(agentBank.bank)} onError={e => addDefaultSrc(e)} alt={agentBank.bank}/>)}
                                </div>
                            </div>
                            <div className=" text-success text-center mb-3">
                                <div><small className="text-muted">ธนาคาร</small></div>
                                <i className="h4 mb-0">
                                    {agentBank && agentBank.bank ? (agentBank.bank) : "กำลังโหลดข้อมูล..."}
                                </i>
                            </div>

                            <div className="text-center mb-3">
                                <div><small className="text-muted">ชื่อบัญชี</small></div>
                                <i className="h4">
                                    {agentBank && agentBank.nameTH ? agentBank.nameTH : "กำลังโหลดข้อมูล..."}
                                </i>
                            </div>

                            <div className="text-center mb-3">
                                <div><small className="text-muted">เลขที่บัญชี</small></div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-6 col">
                                        <div className="number-box">
                                            <h2 className="mb-0">{agentBank && agentBank.number ? agentBank.number : "กำลังโหลดข้อมูล..."}</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {agentBank && agentBank.number && (
                                <div className="mb-3 text-center">
                                    <button onClick={e => copyToClipboard(agentBank.number)} className="btn btn-success">คัดลอกหมายเลขบัญชี</button>
                                </div>
                            )}


                            <div className="text-danger text-center mb-3 font-weight-light">กรุณาโอนเงินจากบัญชี {profile && profile.bankNumber ? profile.bankNumber : ""} {profile && profile.bankAccountName ? profile.bankAccountName : "กำลังโหลดข้อมูล..."} ที่คุณลงทะเบียนไว้ เพื่อความรวดเร็วในการตรวจสอบฝากเครดิต</div>
                            {/*<div className="row justify-content-center">*/}
                            {/*    <div className="col-lg-8">*/}
                            {/*        <Link to="" className="btn btn-primary btn-block btn-lg"><i>เช็คยอดเงิน</i></Link>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    <ProviderGaming/>

                </div>
            </div>

        </>
    );
}


export default Deposit
