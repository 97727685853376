import http from "../http-common";
import {App} from "../config/App";

const enpoint = App.enpoint

const getHistory = (prefix, username, date, page) => {
    return http.get(`${enpoint}/user/gameHistory/${prefix}/${username}?date=${date}&page=${page}`)
}

const getTopup = (prefix, username, to, from, page, type) => {
    return http.get(`${enpoint}/user/topUpHistory/${prefix}/${username}?to=${to}&from=${from}&page=${page}&type=${type}`)
}


export default {
    getHistory,
    getTopup
}
