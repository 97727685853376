import CurrencyFormat from "react-currency-format";
import React from "react";

const Credit = ({profile}) => {
    return (
        <>
            <div className="item-button mb-3">
                <div className="text-center py-3">
                    <div className="text-muted">ยอดเงินที่ใช้ได้</div>
                    <h1 className="mb-0"> <CurrencyFormat value={profile && profile.balance ? profile.balance : 0} displayType={'text'} suffix="" thousandSeparator={true}/></h1>
                </div>
            </div>
        </>
    )
}


export default Credit
