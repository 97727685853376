import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {AiFillDollarCircle, FaArrowLeft, FaHistory} from "react-icons/all";
import {Breadcrumb} from "react-bootstrap";
import ProviderGaming from "../Games/ProviderGaming";
import {AccountContext} from "../../context/AccountContext";
import {HistoryContext} from "../../context/HistoryContext";
import Moment from "react-moment";
import CurrencyFormat from "react-currency-format";
import DatePicker from "react-datepicker";
import moment from 'moment';
import Pagination from "react-js-pagination";
import Credit from "./Credit";


const StatusView = ({status}) => {
    let p = {
        color: "",
        txt: ""
    }
    switch (status) {
        case "failed" :
            p = {color: "danger", txt: "ไม่สำเร็จ"}
            break
        case "success" :
            p = {color: "success", txt: "สำเร็จ"}
            break
        case "pending" :
            p = {color: "info", txt: "รอทำรายการ"}
            break
        case "canceled" :
            p = {color: "danger", txt: "ยกเลิก"}
            break
        case "confirmed" :
            p = {color: "primary", txt: "ยืนยัน"}
            break
    }

    return (
        <span className={"text-" + p.color}>{p.txt}</span>
    )
}

const AmountView = ({item}) => {
    let p = {
        color: "",
        txt: ""
    }
    switch (item.status) {
        case "success" :
            p = {color: "success", txt: "สำเร็จ"}
            break
        case "pending" :
            p = {color: "info", txt: "รอทำรายการ"}
            break
        case "canceled" :
            p = {color: "danger", txt: "ยกเลิก"}
            break
        case "confirmed" :
            p = {color: "primary", txt: "ยืนยัน"}
            break
    }

    return (
        // <span className={"text-" + p.color}>{p.txt}</span>
        <CurrencyFormat value={item.amount} className={`text-${p.color}`} displayType={'text'} thousandSeparator={true}/>
    )
}

const History = () => {

    const {profile} = useContext(AccountContext)
    // const [data, setData] = useState()
    const [date, setDate] = useState({
        start: new Date(moment().subtract('months', 1).format('YYYY-MM-DD')),
        end: new Date(moment().format('YYYY-MM-DD')),
    })
    const {historyState, getTopup, historyData} = useContext(HistoryContext)

    const [activePage, setActivePage] = useState(1)
    const [loading, setLoading] = useState(1)
    const [typeName, setTypeName] = useState('deposit')

    useEffect(() => {
        if (profile && typeof profile.username != 'undefined') {
            getTopup(profile.prefix, profile.username, typeName, activePage, moment(date.end).format("YYYY-MM-DD"), moment(date.start).format("YYYY-MM-DD"))
            setLoading(0)
        }
    }, [profile])


    const handlePageChange = (pageNumber) => {
        console.log(`active page is ${pageNumber}`);
        setActivePage(pageNumber);
        getTopup(profile.prefix, profile.username, typeName, pageNumber, moment(date.end).format("YYYY-MM-DD"), moment(date.start).format("YYYY-MM-DD"))
    }

    const handleSearch = () => {
        setActivePage(1);
        getTopup(profile.prefix, profile.username, typeName, 1, moment(date.end).format("YYYY-MM-DD"), moment(date.start).format("YYYY-MM-DD"))
    }

    const handleTypeName = (v) => {
        setTypeName(v)
    }

    const setStartDate = (v, type) => {
        // console.log(moment().format('YYYY-MM-DD'))
        if (type == 'start')
            setDate({
                ...date,
                start: v
            })
        else
            setDate({
                ...date,
                end: v
            })
    }


    return (
        <>
            <div className="heading-title">
                <div>
                    <h3>ประวัติฝากถอน</h3>
                    <div>ประวัติรายการแจ้งเติม-ถอนเงินย้อนหลัง</div>
                </div>
                <Link to="/dashboard" className="back">
                    <FaArrowLeft/> ย้อนกลับ
                </Link>
            </div>

            <Credit profile={profile}/>


            <div className="row mt-4">
                <div className="col-lg-12">
                    <div className="nor position-relative mb-4">
                        <div className="p-0">

                            <div className="row justify-content-center">
                                <div className="col-lg-12">
                                    <div className="form-inline d-none d-lg-flex mb-4">
                                        <div className="form-group mr-4">
                                            <label className="mr-2">วันที่เริ่มต้น</label>
                                            <DatePicker dateFormat="yyyy-MM-dd" selected={date.start} className="form-control" onChange={(date) => setStartDate(date, 'start')}/>
                                        </div>
                                        <div className="form-group mr-2">
                                            <label className="mr-2">วันที่สิ้นสุด</label>
                                            <DatePicker dateFormat="yyyy-MM-dd" selected={date.end} className="form-control w-100" onChange={(date) => setStartDate(date, 'end')}/>
                                        </div>
                                        <div className="form-group mr-4">
                                            <select className="form-control" onChange={e => handleTypeName(e.target.value)}>
                                                <option value="deposit">เติมเงิน</option>
                                                <option value="withdraw">ถอนเงิน</option>
                                            </select>
                                        </div>
                                        <div className="form-group mr-4">
                                            <button className="btn btn-success btn-sm" onClick={handleSearch}>ค้นหา</button>
                                        </div>
                                    </div>
                                    <div className="row d-lg-none row-sm">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>วันที่เริ่มต้น</label>
                                                <div>
                                                    <DatePicker dateFormat="yyyy-MM-dd" selected={date.start} className="form-control" onChange={(date) => setStartDate(date, 'start')}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>วันที่สิ้นสุด</label>
                                                <div>
                                                    <DatePicker dateFormat="yyyy-MM-dd" selected={date.end} className="form-control" onChange={(date) => setStartDate(date, 'end')}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <select className="form-control" onChange={e => handleTypeName(e.target.value)}>
                                                    <option value="deposit">เติมเงิน</option>
                                                    <option value="withdraw">ถอนเงิน</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <button className="btn btn-success btn-block btn-sm" onClick={handleSearch}>ค้นหา</button>
                                        </div>
                                    </div>
                                    <div className="table-responsive">


                                        <table className="table table-custom table-striped">
                                            <thead>
                                            <tr>
                                                <th className="text-left border-top-0">
                                                    <div className="h6 m-0 text-white"><i>ประเภท</i></div>
                                                </th>
                                                {/*<th width={250} className="text-center border-top-0">*/}
                                                {/*    <div className="h6 m-0 text-white"><i>วันที่</i></div>*/}
                                                {/*</th>*/}

                                                {/*<th className="text-center border-top-0">*/}
                                                {/*    <div className="h6 m-0 text-white"><i>ธนาคาร</i></div>*/}
                                                {/*</th>*/}
                                                {/*<th className="text-center border-top-0">*/}
                                                {/*    <div className="h6 m-0 text-white"><i>วันที่</i></div>*/}
                                                {/*</th>*/}
                                                <th className="text-right border-top-0" width={150}>
                                                    <div className="h6 m-0 text-white"><i>จำนวน</i></div>
                                                </th>
                                                <th className="text-right border-top-0" width={120}>
                                                    <div className="h6 m-0 text-white"><i>โบนัส</i></div>
                                                </th>
                                                {/*<th width={120} className="text-right border-top-0">*/}
                                                {/*    <div className="h6 m-0 text-white"><i>คงเหลือ</i></div>*/}
                                                {/*</th>*/}
                                                <th width={150} className="text-center border-top-0">
                                                    <div className="h6 m-0 text-white"><i>สถานะ</i></div>
                                                </th>
                                                <th width={200} className="text-center border-top-0">
                                                    <div className="h6 m-0 text-white"><i>หมายเหตุ</i></div>
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {historyData && historyData.length > 0 ? (
                                                <>
                                                    {historyData.map((item, index) => (
                                                        <tr key={index}>
                                                            <td className="text-left ">
                                                                {item.txnType == "deposit" ? (
                                                                    <span className="text-success">เติมเงิน</span>) : (
                                                                    <span className="text-danger">ถอนเงิน</span>)}
                                                                <div>
                                                                    <small className="text-muted">
                                                                        <Moment format="YYYY/MM/DD hh:mm">{item.latestUpdate}</Moment>
                                                                    </small>
                                                                </div>
                                                            </td>
                                                            {/*<td className="text-center ">*/}
                                                            {/*   */}
                                                            {/*</td>*/}

                                                            {/*<td className="text-center "></td>*/}
                                                            {/*<td className=" ">*/}

                                                            {/*</td>*/}
                                                            <td className="text-right text-white">
                                                                <AmountView item={item}/>
                                                                {/*<CurrencyFormat value={item.amount} displayType={'text'} thousandSeparator={true}/>*/}
                                                            </td>
                                                            <td className="text-right text-white">
                                                                <CurrencyFormat value={item.bonusAmount} displayType={'text'} thousandSeparator={true}/>
                                                            </td>
                                                            {/*<td className="text-right ">*/}
                                                            {/*    <CurrencyFormat value={item.balance} displayType={'text'} thousandSeparator={true}/>*/}
                                                            {/*</td>*/}
                                                            <td className="text-center">
                                                                <StatusView status={item.status}/>
                                                            </td>
                                                            <td className="text-center ">
                                                                <StatusView status={item.note}/>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </>
                                            ) : (
                                                <tr>
                                                    <td colSpan={6} className="text-center">ไม่พบข้อมูล</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                    {historyData && historyData.length > 0 && (
                                        <div className="d-flex justify-content-center">
                                            <Pagination
                                                itemClass={"page-item"}
                                                linkClass={"page-link"}
                                                activePage={activePage}
                                                itemsCountPerPage={historyState.pageSize}
                                                totalItemsCount={historyState.total ? historyState.total : 0}
                                                pageRangeDisplayed={5}
                                                onChange={e => handlePageChange(e)}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>


                        </div>
                    </div>

                    {/*<ProviderGaming/>*/}

                </div>
            </div>

        </>
    );
}


export default History
