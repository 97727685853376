import {App} from "../../config/App";
import React, {useContext, useEffect, useState} from "react";
import {LoginContext} from "../../context/LoginContext";
import {NotificationManager} from "react-light-notifications";
import {Link, withRouter} from "react-router-dom";
import LocalStorageService from "../../services/LocalStorageService";
import {Swiper, SwiperSlide} from 'swiper/react/swiper-react';
import 'swiper/swiper.min.css';
import ProviderGaming from "../Games/ProviderGaming";
import {FaLine} from "react-icons/fa";


const localStorageService = LocalStorageService.getService();

const Login = () => {
    const {loginState, login, clearRedirect, setSubmit} = useContext(LoginContext)
    const [prefix, setPrefix] = useState("")
    const [ref, setRef] = useState("")
    const [redir, setRedir] = useState("")
    const [data, setData] = useState({
        'username': null,
        'password': null,
    })
    useEffect(() => {
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        const mPrefix = params.get("prefix")
        const mRef = params.get("ref")
        setPrefix(mPrefix)
        setRedir(params.get("redir"))
        clearRedirect()

        if (typeof mPrefix == "undefined" || mPrefix == "" || mPrefix == null || mPrefix == "null") {
            if (App.prefix != ``) {
                let url = `/login?prefix=${App.prefix}`
                if (mRef != "" && mRef != null) {
                    url += `&ref=${mRef}`
                }
                window.location.href = url
            } else {
                NotificationManager.error({
                    title: 'ผิดพลาด',
                    message: "การเข้าใช้งาน Prefix ไม่ถูกต้อง",
                });
            }
            // return;
        }
        if (typeof mRef != 'undefined' && mRef != "" && mRef != null) {
            localStorageService.setRef(mRef)
            setRef(mRef)
        }

    }, [])
    useEffect(() => {
        document.body.classList.add(`login`)
        setSubmit(false)

        if (localStorageService.getAccessToken() != `` && localStorageService.getAccessToken() != null) {
            window.location.href = `/dashboard`
        }


    }, [])


    const handleInput = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }


    const handleLoginAction = (e) => {
        e.preventDefault();
        setSubmit(true)
        login(data.username, data.password, prefix, redir)
    }

    if (loginState.redirect) {
        window.location.href = loginState.redirect
        return (<></>)
    }

    return (
        <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-6 col-md-8">
                <div className="container-fluid my-5">
                    <div className="mb-4 text-center">
                        <div className="mb-2">
                            <img src={App.logo} alt="{App.app_name}" className="w-75"/>
                        </div>
                    </div>

                    <div className="h6 mb-1">เข้าสู่ระบบ</div>
                    <small className="text-muted mb-3 d-block">LIVE CASINO & SLOT BETTING</small>
                    <form onSubmit={e => handleLoginAction(e)}>
                        <div className="mb-4">
                            <div className="form-group">
                                <div className="input-group mb-3">
                                    {prefix && (
                                        <div className="input-group-prepend">
                                            <span className="input-group-text font-weight-bold">{prefix}</span>
                                        </div>
                                    )}
                                    <input type="text" name="username" autoComplete="new-password" placeholder="บัญชีผู้ใช้งาน" onChange={e => handleInput(e)} className="form-control"/>
                                </div>

                            </div>
                            {/*<input type="text" name="xxxxx" />*/}
                            <div className="form-group">
                                <input type="password" name="password" autoComplete="new-password" placeholder="รหัสผ่าน" onChange={e => handleInput(e)} className="form-control"/>
                            </div>
                            {prefix ? (<>
                                {loginState.submitted ? (
                                    <button disabled={true} className="btn btn-warning btn-block">กำลังเข้าสู่ระบบ...</button>) : (
                                    <button onClick={e => handleLoginAction(e)} className="btn btn-warning text-bold  btn-block">เข้าสู่ระบบ</button>)}
                                {ref && ref != null ?
                                    <Link to={`/register?prefix=${prefix}&ref=${ref}`} className="btn btn-dark text-bold btn-block">สมัครสมาชิก</Link> :
                                    <Link to={`/register?prefix=${prefix}`} className="btn btn-dark text-bold btn-block">สมัครสมาชิก</Link>}

                            </>) : (
                                <button disabled={true} className="btn btn-warning btn-block">เข้าใช้งานไม่ถูกต้อง</button>)}
                        </div>
                    </form>

                    <ProviderGaming hideLink={true}/>

                    {/*{App.gameSport && (*/}
                    {/*    <>*/}
                    {/*        <div className="heading-title mb-3">*/}
                    {/*            <div>*/}
                    {/*                <h3>กีฬาออนไลน์</h3>*/}
                    {/*                <div>SPORT GAMING</div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <Swiper*/}
                    {/*            className={"mb-2"}*/}
                    {/*            spaceBetween={8}*/}
                    {/*            slidesPerView={'auto'}*/}
                    {/*        >*/}
                    {/*            <SwiperSlide>*/}
                    {/*                <a href={App.lineUrl}>*/}
                    {/*                    <img src={'/assets/images/games/ufa.jpg?a=1'} alt="banner" className="w-100"/>*/}
                    {/*                </a>*/}
                    {/*            </SwiperSlide>*/}
                    {/*            <SwiperSlide>*/}
                    {/*                <a href={App.lineUrl}>*/}
                    {/*                    <img src={'/assets/images/games/lsm.jpg?a=1'} alt="banner" className="w-100"/>*/}
                    {/*                </a>*/}
                    {/*            </SwiperSlide>*/}
                    {/*            <SwiperSlide>*/}
                    {/*                <a href={App.lineUrl}>*/}
                    {/*                    <img src={'/assets/images/games/muaystep.jpg?a=1'} alt="banner" className="w-100"/>*/}
                    {/*                </a>*/}
                    {/*            </SwiperSlide>*/}
                    {/*        </Swiper>*/}
                    {/*        <div className="text-danger text-center mb-4">** หมายเหตุ เกมส์กีฬาสมัครผ่านไลน์ **</div>*/}
                    {/*    </>*/}
                    {/*)}*/}


                    {typeof App.lineQrcode !== `undefined` && App.lineQrcode !== `` && (
                        <div className="my-5 text-center">
                            <img src={App.lineQrcode} className="w-50" alt=""/>
                            <div className="mt-3">
                                <a href={App.lineUrl} target="_blank" className="btn btn-success px-4 py-1 d-inline-flex align-items-center text-uppercase font-weight-bold">
                                    <FaLine className="mr-2" style={{fontSize: "22px"}}/>
                                    {App.lineId}
                                </a>
                            </div>
                        </div>
                    )}


                    <div className="text-center">
                        <h1 className="h3 text-uppercase font-weight-bold mb-0 text-warning">{App.app_name}</h1>
                        <div className="text-white font-weight-bold ">LIVE CASINO & SLOT BETTING</div>
                        <small>
                            Copyright © 2021 <span className="text-warning text-uppercase">{App.app_name} </span> All rights reserved.
                        </small>
                    </div>

                </div>
            </div>
        </div>
    )
}


export default withRouter(Login)
