import {FaBan, FaExclamationCircle} from "react-icons/all";

const Error403 = () => {
    const styles = {
        'font-size' : `56px`,
        'margin-bottom' : `15px`,
        'text-align' : 'center',
    }
    return (
        <div className="container my-5" style={styles}>
            <FaExclamationCircle className="mb-3"/>
            <div className="text-center h3">Permission Denied.</div>
        </div>
    )
}

export default Error403
